import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addCustomerPopupsSchema-------------
export const addCustomerPopupsSchema = Yup.object()
  .shape({
    targetAudience: Yup.mixed()
      .notOneOf([''], Strings.validation.selectRequired(Strings.field.targetAudience))
      .required(Strings.validation.fieldRequired(Strings.field.targetAudience)),
    oneTimeView: Yup.string().required(Strings.validation.fieldRequired(Strings.field.oneTimeView)),
    header: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.header))
      .test('no-leading-whitespace', 'Header should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    statement1: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.statement1))
      .test('no-leading-whitespace', 'Statements should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    statement2: Yup.string().test(
      'no-leading-whitespace',
      'Statements should not allow white spaces',
      (value) => !value || !/^\s+$/.test(value)
    ),
    statement3: Yup.string().test(
      'no-leading-whitespace',
      'Statements should not allow white spaces',
      (value) => !value || !/^\s+$/.test(value)
    ),
    image: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
  })
  .required();
