import { useEffect, useState } from 'react';
import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get } from 'lodash';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { FormProvider, RHFTextField } from '@app/components';
import { IAddAttributeSchema, IAttribute } from '../types';
import { addAttributeSchema } from '../validator';
import { addAttribute } from '../slice';
import { useRequestState } from '@app/hooks';
import { selectAddAttributeState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IAttribute;
}

const AddAttribute = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();

  const methods = useForm<IAddAttributeSchema>({
    resolver: yupResolver(addAttributeSchema),
    defaultValues: {
      name: get(row, 'name', ''),
    },
  });

  const [initialValues, setInitialValues] = useState<IAddAttributeSchema | null>(null);

  useEffect(() => {
    if (row) {
      setInitialValues(methods.getValues());
    }
  }, [row, methods]);

  const onSubmit = (form: IAddAttributeSchema) => {
    dispatch(addAttribute({ id: row?.id, ...form }));
  };

  const { loading } = useRequestState({
    stateSelector: selectAddAttributeState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editAttribute : Strings.button.addAttribute}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                )
              }
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddAttribute;
