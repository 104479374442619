import { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Box,
  IconButton,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, useWatch } from 'react-hook-form';
import { debounce, get } from 'lodash';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import dayjs from 'dayjs';

import {
  Iconify,
  FormProvider,
  RHFTextField,
  RHFUploadAvatar,
  RHFAutocomplete,
} from '@app/components';
import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { IAddSkuSchema, ISku } from '../types';
import { addSkuSchema } from '../validator';
import { addSku } from '../slice';
import { useDialog, useRequestState } from '@app/hooks';
import { selectAddSkuState } from '../selectors';
import { getProducts, selectProductsState } from '@app/modules/products';
import SelectAttributeDialog from './select-attribute-dialog';
import { IAttribute } from '@app/modules/attribute';
import { Product } from '@app/components/detail-card/types';
import { useTable } from '@app/components/table';
import { IProduct } from '@app/modules/products/types';
import { RowActions } from './row-actions';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ISku;
  id?: string;
  name?: string;
}

const AddSku = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const attributeDialog = useDialog();
  const [selectedAttributes, setSelectedAttributes] = useState<IAttribute[]>([]);
  const [imageErrOpen, setImageErrOpen] = useState(false);
  const [noImage, setNoImage] = useState(false);
  const [initialValues, setInitialValues] = useState<IAddSkuSchema | null>(null);

  const handleAttribute = () => {
    const attrs: IAttribute[] =
      row?.skuAttributes?.map((skuAtt) => ({
        name: skuAtt.attribute.name,
        id: skuAtt.attribute.id,
        attributeValue: skuAtt.attributeValue,
      })) ?? [];

    setSelectedAttributes(attrs);

    onConfirmAttributeSelection(attrs);
  };

  const methods = useForm<IAddSkuSchema>({
    resolver: yupResolver(addSkuSchema(selectedAttributes)) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      productId: get(row?.products, 'name', ''),
      productImage: row?.images?.length ? row.images : '',
      desc: get(row, 'desc', ''),
      attributes: selectedAttributes.reduce((acc: Record<string, string>, attr) => {
        acc[`att_${attr.id}`] = '';
        return acc;
      }, {}),
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'name', ''),
      productId: get(row?.products, 'name', ''),
      productImage: row?.images?.length ? row.images : '',
      desc: get(row, 'desc', ''),
      attributes: {},
    });
    if (row) {
      setInitialValues({
        ...methods.getValues(),
        attributes: row.skuAttributes?.reduce((acc: Record<string, string>, att) => {
          acc[`att_${att.attribute.id}`] = att.attributeValue;
          return acc;
        }, {}),
      });
    }

    handleAttribute();
  }, [row, methods, open]);

  const onConfirmAttributeSelection = (attributes: IAttribute[]) => {
    attributes.forEach((attr: any, index) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      methods.setValue(`attributes.att_${attr.id}`, attr.attributeValue);
    });

    setSelectedAttributes(attributes);
  };
  const tableProps = useTable();
  const page = tableProps.page;

  const [query, setQuery] = useState('');

  const handleProductChange = (event: React.ChangeEvent<any>, value: any | null) => {
    methods.setValue('productId', value);
    if (value) {
      const selectedProduct = productsState?.find((product: any) => product.id === value.id);
      if (selectedProduct) {
        methods.setValue('desc', selectedProduct?.description);
      }
    }
  };

  const handleProductInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setQuery(value);
  }, 300);

  useEffect(() => {
    handleAttribute();
    if (query.length !== 0) {
      dispatch(getProducts({ page, q: query }));
    } else {
      dispatch(getProducts({ page }));
    }
  }, [dispatch, page, query]);

  const onSubmit = (form: IAddSkuSchema) => {
    let product: any = methods.getValues('productId');
    if (typeof product !== 'object' || !product.id) {
      const selectedCategory = productsState?.find((item: any) => item.name === product);
      if (selectedCategory) {
        product = {
          id: selectedCategory.id,
          label: selectedCategory.name,
        };
      }
    }

    const attributes = selectedAttributes.reduce((acc: any, attr) => {
      const value = methods.getValues(`attributes.att_${attr.id}`);
      if (value) {
        acc[attr.id] = value;
      }
      return acc;
    }, {});

    const productImages = methods.getValues('productImage');
    if (productImages.length === 0) {
      setNoImage(true);
    } else if (
      Array.isArray(productImages) &&
      productImages.length >= 1 &&
      productImages.length <= 5
    ) {
      const skuId = 'SKUID-' + dayjs().format('YYYYMMDDHHmmssSSS');
      dispatch(
        addSku({
          id: row?.id,
          ...form,
          productId: form.productId?.id || '',
          productName: product.label,
          skuId,
          productImage: productImages,
          attributes,
          attributesObject: selectedAttributes,
        })
      );
    } else {
      setImageErrOpen(true);
    }
  };

  const { data: productsState, loading: productStateloading } = useRequestState({
    stateSelector: selectProductsState,
  });

  const { loading } = useRequestState({
    stateSelector: selectAddSkuState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const newFiles = acceptedFiles.map((file: any, index) => {
        return Object.assign(file, {
          preview: URL.createObjectURL(file),
        });
      });

      const updatedFiles = row?.images ? [...row.images, ...newFiles] : newFiles;

      const previousImage = methods.watch('productImage');
      methods.setValue('productImage', [...(previousImage || []), ...newFiles], {
        shouldValidate: true,
      });

      if (row) {
        row.images = updatedFiles;
      }
    },
    [methods, row]
  );

  useWatch({ control: methods.control, name: 'productImage' });

  const reorder = ({ list, startIndex, endIndex }: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };
  const onDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = reorder({
      list: selectedAttributes,
      startIndex: result.source.index,
      endIndex: result.destination.index,
    });

    setSelectedAttributes(reorderedItems as IAttribute[]);
  };
  const grid = 8;

  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: 'none',
    padding: `${grid}*2 0`,
    margin: `0 0 ${grid}px 0`,
    background: isDragging ? 'lightgreen' : 'white',
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver: any) => ({
    background: isDraggingOver ? 'lightblue' : 'white',
    padding: grid,
    width: 550,
  });

  const [products, setProducts] = useState<IProduct[]>([]);
  const [productPage, setProductPage] = useState(2);

  useEffect(() => {
    if (productsState) {
      if (!query) {
        setProducts((prevProducts) => [
          ...prevProducts,
          ...productsState.filter(
            (product) => !prevProducts.some((prevProduct) => prevProduct.id === product.id)
          ),
        ]);
      } else {
        setProducts(productsState);
      }
    }
  }, [productsState, query, setProducts]);

  const handleCallProductApi = async () => {
    const nextPage = productPage + 1;
    setProductPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getProducts({ page: productPage }));
      resolve();
    });
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editSku : Strings.button.addSku}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField name="name" label={Strings.field.name} fullWidth variant="outlined" />

              <RHFAutocomplete
                name="productId"
                label={Strings.field.product}
                options={((products as Product[]) || []).map((product) => ({
                  label: product.name,
                  id: product.id,
                }))}
                onInputChange={handleProductInputChange}
                onChange={handleProductChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: Product, value: Product) => option.id === value.id}
                callApi={handleCallProductApi}
                hasMore={productsState?.length !== 0 && !query}
                loading={productStateloading}
              />

              <>
                <RHFTextField
                  name="desc"
                  required
                  label={Strings.field.description}
                  fullWidth
                  variant="outlined"
                  multiline
                  minRows={2}
                />
                <Box display="flex" marginTop={2} flexWrap={'wrap'} position="relative">
                  {Array.isArray(methods.watch('productImage')) &&
                    methods.watch('productImage').map((file: any, index: number) => (
                      <Box
                        key={index}
                        sx={{
                          position: 'relative',
                          width: 115,
                          height: 115,
                          margin: '5px',
                          borderRadius: '10px',
                        }}
                      >
                        <img
                          src={file?.preview ? file?.preview : file}
                          alt={`Preview ${index}`}
                          style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '10px',
                          }}
                        />
                        <IconButton
                          onClick={() => {
                            methods.setValue(
                              'productImage',
                              methods.watch('productImage').filter((image: any) => image !== file)
                            );
                          }}
                          sx={{
                            position: 'absolute',
                            height: 25,
                            width: 25,
                            top: 0,
                            right: 0,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            color: '#fff',
                            zIndex: 1,
                            '&:hover': {
                              backgroundColor: 'rgba(0, 0, 0, 0.7)',
                            },
                          }}
                        >
                          <Iconify icon="oi:x" />
                        </IconButton>
                      </Box>
                    ))}
                  <RHFUploadAvatar
                    multiple
                    name={'productImage'}
                    onDrop={handleDrop}
                    sx={{
                      borderRadius: '10px',
                      width: 115,
                      height: 115,
                      border: 'none',
                      m: '5px',
                      background: 'grey',
                      opacity: 0.72,
                    }}
                    placeholderSx={{
                      borderRadius: '10px',
                      width: '100%',
                      height: '100%',
                      border: '5px dashed rgba(0, 0, 0, 0.08)',
                    }}
                  />
                </Box>

                {imageErrOpen && (
                  <Stack style={{ color: 'red' }} textAlign={'center'}>
                    {Strings.validation.fiveImgError}
                  </Stack>
                )}
                {noImage && (
                  <Stack style={{ color: 'red' }} textAlign={'center'}>
                    {Strings.validation.chooseImg}
                  </Stack>
                )}
                <Button variant="contained" onClick={attributeDialog.show}>
                  {Strings.button.selectAttribute}
                </Button>
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="unique">
                    {(provided, snapshot) => (
                      <Box
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {selectedAttributes?.map((attr, index) => {
                          return (
                            <Draggable
                              key={attr.id.toString()}
                              draggableId={attr.id.toString()}
                              index={index}
                            >
                              {(provided, snapshot) => (
                                <Box
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <Box style={{ display: 'flex' }}>
                                    <RowActions
                                      handleDelete={() => {
                                        setSelectedAttributes(
                                          selectedAttributes?.filter(
                                            (attri) => attri?.id !== attr.id
                                          )
                                        );
                                      }}
                                    />
                                    <RHFTextField
                                      key={attr.id}
                                      name={`attributes.${`att_${attr.id}`}`}
                                      required
                                      label={attr.name}
                                      multiline
                                      fullWidth
                                      variant="outlined"
                                    />
                                  </Box>
                                </Box>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </Box>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() ?? {}).map(([key, value]) => [
                      key,
                      typeof value === 'object' && value !== null
                        ? JSON.stringify(value)
                        : value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues ?? {}).map(([key, value]) => [
                      key,
                      typeof value === 'object' && value !== null
                        ? JSON.stringify(value)
                        : value?.toString() ?? '',
                    ])
                  )
                )
              }
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>

      {attributeDialog.visible && (
        <SelectAttributeDialog
          open
          onClose={attributeDialog.hide}
          onSubmit={(selected) => {
            const updatedSelected = selected?.map((sel) => {
              const getData = row?.skuAttributes?.filter(
                (att: any) => att?.attributeId === sel?.id
              );
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              const watchedValue = methods.watch(`attributes.${`att_${sel.id}`}`);
              return {
                id: sel.id,
                name: sel.name,
                attributeValue:
                  watchedValue?.length > 0 ? watchedValue : getData?.[0]?.attributeValue,
              };
            });
            onConfirmAttributeSelection(updatedSelected);
            attributeDialog.hide();
          }}
          selectedAttributes={selectedAttributes}
        />
      )}
    </>
  );
};

export default AddSku;
