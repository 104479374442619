/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import { RootState } from '@app/redux/root-reducer';
import {
  getCustomerPopups,
  getCustomerPopupsFailed,
  getCustomerPopupsSuccess,
  addCustomerPopup,
  addCustomerPopupFailed,
  addCustomerPopupSuccess,
  deleteCustomerPopup,
  deleteCustomerPopupFailed,
  deleteCustomerPopupSuccess,
  updateCustomerPopupStatus,
  updateCustomerPopupStatusFailed,
  updateCustomerPopupStatusSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { ICustomerPopup } from './types';

// ----------------------------------------

function* getCustomerPopupsSaga(action: ReturnType<typeof getCustomerPopups>): any {
  try {
    const { type, q } = action.payload;
    const result = yield call(request.get, `${endpoints.getCustomerPopups}?type=${type}&q=${q}`);
    yield put(getCustomerPopupsSuccess(result.data.data));
  } catch (err: any) {
    yield put(getCustomerPopupsFailed(err));
  }
}

// ---------------------------------------

function* addCustomerPopupSaga(action: ReturnType<typeof addCustomerPopup>): any {
  try {
    const { data } = yield select((store: RootState) => store.customerPopupReducer.customerPopup);

    const {
      header,
      image,
      statement1,
      statement2,
      statement3,
      targetAudience,
      id,
      selectedTargetAudience,
      oneTimeView,
    } = action.payload;
    const formData = new FormData();

    formData.append('targetAudience', targetAudience);
    formData.append('header', header);
    formData.append('statement1', statement1);
    formData.append('statement2', statement2.trim() || '');
    formData.append('statement3', statement3.trim() || '');
    formData.append('oneTimeView', oneTimeView);

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    let result: any;
    if (id) {
      result = yield call(request.put, `${endpoints.updateCustomerPopups}?id=${id}`, formData);
    } else {
      result = yield call(request.post, endpoints.addCustomerPopup, formData);
    }
    let updated: any = [];

    if (selectedTargetAudience === targetAudience) {
      if (id) {
        updated =
          data &&
          data?.length > 0 &&
          data?.map((item: ICustomerPopup) => {
            if (item?.id === result?.data?.data?.id) {
              return result?.data?.data;
            } else {
              return item;
            }
          });
      } else {
        updated = [result?.data?.data, ...data];
      }
      yield put(getCustomerPopupsSuccess(updated));
    }

    yield put(addCustomerPopupSuccess(result.data));
  } catch (err: any) {
    yield put(addCustomerPopupFailed(err));
  }
}

// ----------------------------------------

function* deleteCustomerPopupSaga(action: ReturnType<typeof deleteCustomerPopup>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.customerPopupReducer.customerPopup);

    const result = yield call(request.delete, `${endpoints.deleteCustomerPopups}?id=${id}`);

    const updatedData = data?.filter((item: { id: number }) => item?.id !== id);
    yield put(getCustomerPopupsSuccess(updatedData));

    yield put(deleteCustomerPopupSuccess(result.data));
  } catch (err: any) {
    yield put(deleteCustomerPopupFailed(err));
  }
}

// ----------------------------------------

function* updateCustomerPopupStatusSaga(action: ReturnType<typeof updateCustomerPopupStatus>): any {
  try {
    const { data } = yield select((store: RootState) => store.customerPopupReducer.customerPopup);

    const { id } = action.payload;

    const result = yield call(request.patch, `${endpoints.updateCustomerPopupStatusById}${id}`);
    const updatedData = data?.map((item: ICustomerPopup) => {
      if (item.id === id) {
        return { ...item, active: !item.active };
      } else {
        return { ...item, active: false };
      }
    });
    yield put(getCustomerPopupsSuccess(updatedData));
    yield put(updateCustomerPopupStatusSuccess(result?.data?.message));
  } catch (err: any) {
    yield put(updateCustomerPopupStatusFailed(err));
  }
}

// ----------------------------------------

function* customerPopupsSagas() {
  yield all([
    takeLatest(getCustomerPopups, getCustomerPopupsSaga),
    takeLatest(addCustomerPopup, addCustomerPopupSaga),
    takeLatest(deleteCustomerPopup, deleteCustomerPopupSaga),
    takeLatest(updateCustomerPopupStatus, updateCustomerPopupStatusSaga),
  ]);
}

export { customerPopupsSagas };
