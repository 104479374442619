/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  editInventoryStock,
  addBulkInventory,
  addBulkInventoryFailed,
  addBulkInventorySuccess,
  addInventory,
  addInventoryFailed,
  addInventorySuccess,
  getInventory,
  getInventoryDetail,
  getInventoryDetailFailed,
  getInventoryDetailSuccess,
  getInventoryFailed,
  getInventorySuccess,
  editInventoryStockSuccess,
  editInventoryStockFailed,
  editInventory,
  editInventorySuccess,
  editInventoryFailed,
} from './slice';
import * as endpoints from './enpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getInventorySaga(action: ReturnType<typeof getInventory>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getInventory, {
      params: {
        page: q ? 1 : page,
        q,
        limit,
      },
    });
    yield put(getInventorySuccess(result.data.inventories));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getInventoryFailed(err));
  }
}
// ----------------------------------------
function* getInventoryDetailSaga(action: ReturnType<typeof getInventoryDetail>): any {
  const { id } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getInventoryDetail + id);
    yield put(getInventoryDetailSuccess(result.data.data));
  } catch (err: any) {
    yield put(getInventoryDetailFailed(err));
  }
}
// ----------------------------------------
function* editInventoryStockSagas(action: ReturnType<typeof editInventoryStock>): any {
  try {
    const { id, stock, operation, reason } = action.payload;
    const { data } = yield select((store: RootState) => store.inventorySlice.inventories);

    const convertedData = {
      operation,
      stock: Number(stock),
      reason,
    };
    let updatedData;

    const result = yield call(request.put, endpoints.updateStock + id, convertedData);

    const index = data.findIndex((obj: any) => obj.id === result.data.data.id);

    if (index !== -1) {
      updatedData = [...data];
      updatedData[index] = {
        ...updatedData[index],
        stock: Number(result.data.data.stock),
      };
    }

    yield put(editInventoryStockSuccess(result.data.data));
    yield put(getInventorySuccess(updatedData));
  } catch (err: any) {
    yield put(editInventoryStockFailed(err));
  }
}
// ----------------------------------------
function* editInventorySagas(action: ReturnType<typeof editInventory>): any {
  try {
    const { id, price, ourPrice, discountPrice } = action.payload;
    const { data } = yield select((store: RootState) => store.inventorySlice.inventoryDetail);

    const convertedData = {
      price: Number(price),
      ourPrice: Number(ourPrice),
      discountPrice: Number(discountPrice),
    };

    const result = yield call(request.put, endpoints.updateInventory + id, convertedData);

    yield put(editInventorySuccess(result.data.data));
    yield put(
      getInventoryDetailSuccess({
        ...data,
        ...result?.data?.data,
      })
    );
  } catch (err: any) {
    yield put(editInventoryFailed(err));
  }
}

function* addInventorySagas(action: ReturnType<typeof addInventory>): any {
  try {
    const { id, ourPrice, price, skuId, stock, vendorId, discountPrice } = action.payload;
    const { data } = yield select((store: RootState) => store.inventorySlice.inventoryDetail);

    let result;

    const convertedData = {
      ourPrice: Number(ourPrice),
      price: Number(price),
      skuId: Number(skuId),
      stock: Number(stock),
      vendorId: Number(vendorId),
      discountPrice,
      operation: 'add',
      reason: 'Inbound against PO',
    };

    if (id) {
      result = yield call(request.put, endpoints.updateInventory + id, convertedData);
    } else {
      result = yield call(request.post, endpoints.addInventory, convertedData);
    }
    yield put(addInventorySuccess(result.data));
    yield put(
      getInventoryDetailSuccess({
        ...data,
        ...result.data.data,
      })
    );
  } catch (err: any) {
    yield put(addInventoryFailed(err));
  }
}

// ----------------------------------------

function* addBulkInventorySagas(action: ReturnType<typeof addBulkInventory>): any {
  const { fileData } = action.payload;
  try {
    if (fileData) {
      const formattedData = fileData.map((item: any) => ({
        stock: item.operation === 'none' && !item.stock ? 0 : Number(item.stock),
        price: Number(item.price),
        ourPrice: Number(item.ourPrice),
        vendorId: Number(item.vendorId),
        skuId: Number(item.skuId),
        discountPrice: Number(item.discountPrice),
        reason: item.reason,
        operation: item.operation,
      }));
      const result = yield call(request.post, endpoints.addInventories, formattedData);
      if (result.data.status) {
        yield put(getInventorySuccess(result.data.inventories));
        yield put(addBulkInventorySuccess(result.data.message));
      } else {
        yield put(addBulkInventoryFailed(result.data));
      }
    }
  } catch (err: any) {
    yield put(addBulkInventoryFailed(err));
  }
}

function* inventorySagas() {
  yield all([
    takeLatest([getInventory, addInventorySuccess, addBulkInventorySuccess], getInventorySaga),
    takeLatest([addInventory], addInventorySagas),
    takeLatest([editInventoryStock], editInventoryStockSagas),
    takeLatest([getInventoryDetail], getInventoryDetailSaga),
    takeLatest([addBulkInventory], addBulkInventorySagas),
    takeLatest([editInventory], editInventorySagas),
  ]);
}

export { inventorySagas };
