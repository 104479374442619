import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IAddCollectionSchema, ICollection, ICollectionState } from './types';

const initialState: ICollectionState = {
  collection: {},
  addCollection: {},
  updateCollectionStatus: {},
};

const slice = createSlice({
  name: 'collection',
  initialState,
  reducers: {
    getCollection(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.collection.status = RequestStatus.RUNNING;
    },
    getCollectionSuccess(state, action: PayloadAction<ICollectionState['collection']['data']>) {
      state.collection.status = RequestStatus.SUCCESS;
      state.collection.data = action.payload;
    },
    getCollectionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.collection.status = RequestStatus.ERROR;
      state.collection.error = action.payload;
    },

    //

    updateCollectionStatus(state) {
      state.updateCollectionStatus.status = RequestStatus.RUNNING;
    },
    updateCollectionStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updateCollectionStatus.status = RequestStatus.SUCCESS;
      state.updateCollectionStatus.data = action.payload;
    },
    updateCollectionStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateCollectionStatus.status = RequestStatus.ERROR;
      state.updateCollectionStatus.error = action.payload;
    },

    //

    addCollection(state, action: PayloadAction<IAddCollectionSchema>) {
      state.addCollection.status = RequestStatus.RUNNING;
    },
    addCollectionSuccess(state, action: PayloadAction<ICollection>) {
      state.addCollection.status = RequestStatus.SUCCESS;
      state.addCollection.data = action.payload;
    },
    addCollectionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addCollection.status = RequestStatus.ERROR;
      state.addCollection.error = action.payload;
    },

    //
  },
});
export default slice.reducer;

export const {
  getCollection,
  getCollectionSuccess,
  getCollectionFailed,
  addCollection,
  addCollectionSuccess,
  addCollectionFailed,
  updateCollectionStatus,
  updateCollectionStatusSuccess,
  updateCollectionStatusFailed,
} = slice.actions;
