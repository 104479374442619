import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IAddBannerSchema, IBanner, IBannerState } from './types';

const initialState: IBannerState = {
  banner: {},
  bannerStatus: {},
  addBanner: {},
};

const slice = createSlice({
  name: 'banner',
  initialState,
  reducers: {
    getBanner(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.banner.status = RequestStatus.RUNNING;
    },
    getBannerSuccess(state, action: PayloadAction<IBannerState['banner']['data']>) {
      state.banner.status = RequestStatus.SUCCESS;
      state.banner.data = action.payload;
      console.log('banner', state.banner.data);
    },
    getBannerFailed(state, action: PayloadAction<IRequestFailed>) {
      state.banner.status = RequestStatus.ERROR;
      state.banner.error = action.payload;
    },

    //

    updateBannerStatus(state) {
      state.bannerStatus.status = RequestStatus.RUNNING;
    },
    getUpdateBannerStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.bannerStatus.status = RequestStatus.SUCCESS;
      state.bannerStatus.data = action.payload;
    },
    getUpdateBannerStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.bannerStatus.status = RequestStatus.ERROR;
      state.banner.error = action.payload;
    },
    //
    addBanner(state, action: PayloadAction<IAddBannerSchema>) {
      state.addBanner.status = RequestStatus.RUNNING;
    },
    addBannerSuccess(state, action: PayloadAction<IBanner>) {
      state.addBanner.status = RequestStatus.SUCCESS;
      state.addBanner.data = action.payload;
    },
    addBannerFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addBanner.status = RequestStatus.ERROR;
      state.addBanner.error = action.payload;
    },

    //
  },
});
export default slice.reducer;

export const {
  getBanner,
  getBannerSuccess,
  getBannerFailed,
  updateBannerStatus,
  getUpdateBannerStatusSuccess,
  getUpdateBannerStatusFailed,
  addBanner,
  addBannerFailed,
  addBannerSuccess,
} = slice.actions;
