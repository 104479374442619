import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { IAddAttributeSchema, IAttribute, IAttributeState } from './types';

const initialState: IAttributeState = {
  attributes: {},
  addAttribute: {},
  attributeDelete: {},
};

const slice = createSlice({
  name: 'attribute',
  initialState,
  reducers: {
    getAttributes(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.attributes.status = RequestStatus.RUNNING;
    },
    getAttributesSuccess(state, action: PayloadAction<IAttributeState['attributes']['data']>) {
      state.attributes.status = RequestStatus.SUCCESS;
      state.attributes.data = action.payload;
    },
    getAttributesFailed(state, action: PayloadAction<IRequestFailed>) {
      state.attributes.status = RequestStatus.ERROR;
      state.attributes.error = action.payload;
    },
    //

    addAttribute(state, action: PayloadAction<IAddAttributeSchema>) {
      state.addAttribute.status = RequestStatus.RUNNING;
    },
    addAttributeSuccess(state, action: PayloadAction<IAttribute>) {
      state.addAttribute.status = RequestStatus.SUCCESS;
      state.addAttribute.data = action.payload;
    },
    addAttributeFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addAttribute.status = RequestStatus.ERROR;
      state.addAttribute.error = action.payload;
    },
    //
    attributeDelete(state, action: PayloadAction<{ id: number }>) {
      state.attributeDelete.status = RequestStatus.RUNNING;
    },
    attributeDeleteSuccess(state, action: PayloadAction<{ id: any }>) {
      state.attributeDelete.status = RequestStatus.SUCCESS;
      state.attributeDelete.data = action.payload;
    },
    attributeDeleteFailed(state, action: PayloadAction<IRequestFailed>) {
      state.attributeDelete.status = RequestStatus.ERROR;
      state.attributeDelete.error = action.payload;
    },
    //
  },
});
export default slice.reducer;

export const {
  getAttributes,
  getAttributesSuccess,
  getAttributesFailed,
  addAttribute,
  addAttributeSuccess,
  addAttributeFailed,
  attributeDelete,
  attributeDeleteSuccess,
  attributeDeleteFailed,
} = slice.actions;
