import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { debounce, get } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFTextField, RHFAutocomplete } from '@app/components';
import { IAddInventorySchema, IAddSku, IInventory, IAddUser } from '../types';
import { addInventorySchema } from '../validators';
import { selectEditInventoryState } from '../selectors';
import { editInventory } from '../slice';
import { selectSkuState } from '@app/modules/sku/selectors';
import { selectUsersState } from '@app/modules/users/selectors';
import { getSku } from '@app/modules/sku';
import { useTable } from '@app/components/table';
import { getUsers } from '@app/modules/users';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IInventory;
}

const EditInventoryData = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const [skuSearchingData, setSkuSearchingData] = useState('');
  const [usersSearchingData, setUsersSearchingData] = useState('');
  const [initialValues, setInitialValues] = useState<IAddInventorySchema | null>(null);

  const methods = useForm<IAddInventorySchema>({
    resolver: yupResolver(addInventorySchema) as any,
    defaultValues: {
      stock: get(row, 'stock', ''),
      price: get(row, 'price', ''),
      ourPrice: get(row, 'ourPrice', ''),
      vendorId: get(row, 'vendorId', ''),
      skuId: get(row, 'skuId', ''),
      discountPrice: get(row, 'discountPrice', ''),
    },
  });

  useEffect(() => {
    methods.reset({
      stock: get(row, 'stock', ''),
      price: get(row, 'price', ''),
      ourPrice: get(row, 'ourPrice', ''),
      vendorId: get(row, 'vendorId', ''),
      skuId: get(row, 'skuId', ''),
      discountPrice: get(row, 'discountPrice', ''),
    });
    if (row) {
      setInitialValues(methods.getValues());
    }
  }, [row, methods]);

  const tableProps = useTable();
  const page = tableProps.page;

  const handleSkuInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setSkuSearchingData(value);
  }, 300);

  const handleUserInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setUsersSearchingData(value);
  }, 300);

  useEffect(() => {
    if (usersSearchingData.length !== 0) {
      dispatch(getUsers({ page, q: usersSearchingData }));
    } else {
      dispatch(getUsers({ page }));
    }
  }, [dispatch, page, usersSearchingData]);

  useEffect(() => {
    if (skuSearchingData.length !== 0) {
      dispatch(getSku({ page, q: skuSearchingData }));
    } else {
      dispatch(getSku({ page }));
    }
  }, [dispatch, page, skuSearchingData]);

  const onSubmit = (form: IAddInventorySchema) => {
    const id = row?.id ?? 0;
    const discountPrice = parseFloat(form.discountPrice);
    dispatch(
      editInventory({
        ...form,
        id,
        discountPrice,
      })
    );
  };

  const { loading } = useRequestState({
    stateSelector: selectEditInventoryState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const { data: skus } = useRequestState({ stateSelector: selectSkuState });

  const { data: users } = useRequestState({ stateSelector: selectUsersState });

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header variant="dialog" title={Strings.pageTitle.editInventory} onClose={onClose} />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="price"
                required
                label={Strings.field.price}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name="ourPrice"
                required
                label={Strings.field.ourPrice}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name="discountPrice"
                label={Strings.field.discountPrice}
                fullWidth
                variant="outlined"
                required
              />

              <RHFAutocomplete
                name="vendorId"
                label={Strings.field.vendor}
                options={((users as IAddUser[]) || [])
                  .filter((usr) => usr.userType === 'VU')
                  .map((user) => ({
                    label: user.firstName,
                    id: user.id,
                  }))}
                onInputChange={handleUserInputChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
              />

              <RHFAutocomplete
                name="skuId"
                label={Strings.field.sku}
                options={((skus as IAddSku[]) || []).map((sku) => ({
                  label: sku.name,
                  id: sku.id,
                }))}
                onInputChange={handleSkuInputChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: IAddSku, value: IAddSku) => option.id === value.id}
              />
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                )
              }
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default EditInventoryData;
