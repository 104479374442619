import { useCallback, useEffect, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  MenuItem,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { debounce, get } from 'lodash';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFAutocomplete, RHFTextField, RHFUploadAvatar } from '@app/components';
import { IAddCategorySchema, IAddParentCategory, ICategory } from '../types';
import { addCategorySchema } from '../validator';
import {
  selectAddCategoryState,
  selectAddParentCategoryState,
  selectParentCategory,
} from '../selectors';
import { addCategory, addParentCategory, getParentCategory } from '../slice';
import { IMAGE_BASE_URL } from '@app/configs';
import { useTable } from '@app/components/table';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ICategory;
  title?: string;
  isParentCategory?: boolean;
}

const AddCategory = ({ open, onClose, row, title, isParentCategory }: Props) => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const page = tableProps.page;

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [parentCategory, setParentCategory] = useState<IAddParentCategory[]>([]);
  const [parentCategoryPage, setParentCategoryPage] = useState(2);
  const [query, setQuery] = useState('');
  const [initialValues, setInitialValues] = useState<IAddCategorySchema | null>(null);

  const { data: parentCategoryState, loading } = useRequestState({
    stateSelector: selectParentCategory,
  });

  const methods = useForm<IAddCategorySchema>({
    resolver: yupResolver(addCategorySchema) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      parentCategory: get(row?.parentCategory, 'name', ''),
      image: row?.image ? IMAGE_BASE_URL + row.image : '',
      type: get(row, 'type', ''),
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'name', ''),
      parentCategory: get(row?.parentCategory, 'name', ''),
      image: row?.image ? IMAGE_BASE_URL + row.image : '',
      type: get(row, 'type', ''),
    });
    if (row) {
      setInitialValues(methods.getValues());
    }
  }, [row, methods]);

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );

  const onSubmit = (form: IAddCategorySchema) => {
    const id = row?.id ?? 0;
    let parentCategory: any = methods.getValues('parentCategory');
    if (typeof parentCategory !== 'object' || !parentCategory.id) {
      const parentCategoryFound = parentCategoryState?.find(
        (item: any) => item.name === parentCategory
      );
      if (parentCategoryFound) {
        parentCategory = {
          id: parentCategoryFound.id,
          label: parentCategoryFound.name,
        };
      }
    }
    dispatch(
      addCategory({
        ...form,
        image: methods.getValues('image'),
        id,
        parentCategory: parentCategory?.id,
        parentCategoryName: parentCategory?.label,
      })
    );
  };

  const onSubmitParentCategory = (form: IAddParentCategory) => {
    const id = row?.id ?? 0;
    dispatch(
      addParentCategory({
        ...form,
        image: methods.getValues('image'),
        id,
      })
    );
  };

  const handleParentCategoryChange = debounce((event: React.ChangeEvent<any>, value) => {
    setQuery(value);
  }, 300);

  useEffect(() => {
    dispatch(getParentCategory({ page, q: query }));
  }, [dispatch, query]);

  const { loading: saveCategory } = useRequestState({
    stateSelector: selectAddCategoryState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const { loading: saveParentCategory } = useRequestState({
    stateSelector: selectAddParentCategoryState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleCallParentCategoryApi = async () => {
    const nextPage = parentCategoryPage + 1;
    setParentCategoryPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getParentCategory({ page: parentCategoryPage, q: query }));
      resolve();
    });
  };

  useEffect(() => {
    if (parentCategoryState) {
      if (!query) {
        setParentCategory((prevParentCategories) => [
          ...prevParentCategories,
          ...parentCategoryState.filter(
            (parentCategory: { id: number | undefined }) =>
              !prevParentCategories.some((prevCategory) => prevCategory.id === parentCategory.id)
          ),
        ]);
      } else {
        setParentCategory(parentCategoryState);
      }
    }
  }, [parentCategoryState, query]);

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={
              title ?? (row?.id ? Strings.pageTitle.editCategory : Strings.button.addNewCategory)
            }
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />
              {!isParentCategory && (
                <RHFAutocomplete
                  name="parentCategory"
                  label={Strings.field.parentCategory}
                  options={(parentCategory || []).map((category) => ({
                    label: category.name,
                    id: category.id,
                  }))}
                  onInputChange={handleParentCategoryChange}
                  getOptionKey={(option: any) => option?.id}
                  isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                  callApi={handleCallParentCategoryApi}
                  hasMore={parentCategoryState?.data?.length !== 0 && !query}
                  loading={loading}
                />
              )}
              <RHFTextField
                name="type"
                required
                label={Strings.field.type}
                variant="outlined"
                select
              >
                <MenuItem value={'product'}>Product</MenuItem>
                <MenuItem value={'service'}>Service</MenuItem>
              </RHFTextField>

              <Stack alignItems={'start'}>
                <RHFUploadAvatar
                  name={'image'}
                  onDrop={handleDrop}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                    border:
                      isImageUploaded || row?.image ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                  }}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={isParentCategory ? saveParentCategory : saveCategory}
              type="submit"
              variant="contained"
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                )
              }
              onClick={methods.handleSubmit(isParentCategory ? onSubmitParentCategory : onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddCategory;
