import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed } from '@app/types';
import { ICustomerPopupState, IAddCustomerPopup } from './types';

const initialState: ICustomerPopupState = {
  addCustomerPopup: {},
  customerPopup: {},
  deleteCustomerPopup: {},
  updateCustomerPopupStatus: {},
};

const slice = createSlice({
  name: 'customerPopup',
  initialState,
  reducers: {
    getCustomerPopups(state, action: PayloadAction<{ type: string; q: string }>) {
      state.customerPopup.status = RequestStatus.RUNNING;
    },
    getCustomerPopupsSuccess(
      state,
      action: PayloadAction<ICustomerPopupState['customerPopup']['data']>
    ) {
      state.customerPopup.status = RequestStatus.SUCCESS;
      state.customerPopup.data = action.payload;
    },
    getCustomerPopupsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.customerPopup.status = RequestStatus.ERROR;
      state.customerPopup.error = action.payload;
    },
    // ---------------------------------------------------
    addCustomerPopup(state, action: PayloadAction<IAddCustomerPopup>) {
      state.addCustomerPopup.status = RequestStatus.RUNNING;
    },
    addCustomerPopupSuccess(state, action: PayloadAction<IAddCustomerPopup>) {
      state.addCustomerPopup.status = RequestStatus.SUCCESS;
      state.addCustomerPopup.data = action.payload;
    },
    addCustomerPopupFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addCustomerPopup.status = RequestStatus.ERROR;
      state.addCustomerPopup.error = action.payload;
    },
    // --------------------------------------------------
    deleteCustomerPopup(state, action: PayloadAction<{ id: number }>) {
      state.deleteCustomerPopup.status = RequestStatus.RUNNING;
    },
    deleteCustomerPopupSuccess(state, action: PayloadAction<{ id: number }>) {
      state.deleteCustomerPopup.status = RequestStatus.SUCCESS;
      state.deleteCustomerPopup.data = action.payload;
    },
    deleteCustomerPopupFailed(state, action: PayloadAction<IRequestFailed>) {
      state.deleteCustomerPopup.status = RequestStatus.ERROR;
      state.deleteCustomerPopup.error = action.payload;
    },
    // --------------------------------------------------
    updateCustomerPopupStatus(state, action: PayloadAction<{ id: number }>) {
      state.updateCustomerPopupStatus.status = RequestStatus.RUNNING;
    },
    updateCustomerPopupStatusSuccess(state, action: PayloadAction<{ id: number }>) {
      state.updateCustomerPopupStatus.status = RequestStatus.SUCCESS;
      state.updateCustomerPopupStatus.data = action.payload;
    },
    updateCustomerPopupStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateCustomerPopupStatus.status = RequestStatus.ERROR;
      state.updateCustomerPopupStatus.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getCustomerPopups,
  getCustomerPopupsFailed,
  getCustomerPopupsSuccess,
  addCustomerPopup,
  addCustomerPopupFailed,
  addCustomerPopupSuccess,
  deleteCustomerPopup,
  deleteCustomerPopupFailed,
  deleteCustomerPopupSuccess,
  updateCustomerPopupStatus,
  updateCustomerPopupStatusFailed,
  updateCustomerPopupStatusSuccess,
} = slice.actions;
