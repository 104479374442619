import { useEffect, useState } from 'react';
import { Button, Stack, useTheme, LinearProgress } from '@mui/material';
import { useDispatch } from 'react-redux';

import { SearchBar } from '@app/components';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import AddCustomerPopup from './add-customer-popup';
import { getCustomerPopups } from '../slice';
import { selectCustomerPopups } from '../selectors';
import { ICustomerPopup } from '../types';
import { CustomerPopupCard } from './customer-popup-card';

const CustomerPopupsPage = () => {
  const customerPopupDialog = useDialog();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedTargetAudience, setSelectedTargetAudience] = useState<
    'New customers' | 'Existing customers'
  >('New customers');

  useEffect(() => {
    if (searchTerm.length !== 0) {
      dispatch(getCustomerPopups({ type: selectedTargetAudience, q: searchTerm }));
    } else {
      dispatch(getCustomerPopups({ type: selectedTargetAudience, q: '' }));
    }
  }, [searchTerm, dispatch, selectedTargetAudience]);

  const { data, loading } = useRequestState({
    stateSelector: selectCustomerPopups,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const targetAudience = [
    { label: 'New Customers', type: 'New customers' },
    { label: 'Existing Customers', type: 'Existing customers' },
  ];

  return (
    <>
      <Header
        title={Strings.pageTitle.customerPopups}
        helperText={''}
        actionButtonTitle={Strings.button.addPopup}
        onClickActionButton={customerPopupDialog.show}
      />
      {/* Tab Buttons */}
      <Stack direction="row" px={2}>
        {targetAudience.map((item, index) => (
          <Button
            key={index}
            variant="text"
            onClick={() => {
              setSelectedTargetAudience(
                item.label === 'New Customers' ? 'New customers' : 'Existing customers'
              );
            }}
            sx={{
              fontSize: '16px',
              fontWeight: 'normal',
              color:
                selectedTargetAudience === item.type
                  ? theme.palette.common.black
                  : theme.palette.grey[600],
              position: 'relative',
              pb: 1,
              '&::after': {
                content: `""`,
                position: 'absolute',
                width: '100%',
                height: '6px',
                backgroundColor:
                  selectedTargetAudience === item.type
                    ? theme.palette.primary.light
                    : 'transparent',
                bottom: '0',
                left: '0',
                borderRadius: '10px',
              },
            }}
          >
            {item.label}
          </Button>
        ))}
      </Stack>
      <SearchBar
        sx={{ px: '15px', mt: '10px' }}
        placeholder={Strings.customerPopup.searchWithHeading}
        onChange={handleSearchChange}
      />
      {loading ? (
        <LinearProgress sx={{ m: 2 }} />
      ) : (
        <Stack
          sx={{
            px: 2,
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {data &&
            data?.length > 0 &&
            data?.map((item: ICustomerPopup, index: number) => {
              return (
                <Stack key={index}>
                  <CustomerPopupCard key={item.id} cardDetails={item} />
                </Stack>
              );
            })}
        </Stack>
      )}
      {customerPopupDialog.visible && (
        <AddCustomerPopup
          open
          onClose={customerPopupDialog.hide}
          selectedTargetAudience={selectedTargetAudience}
        />
      )}
    </>
  );
};

export { CustomerPopupsPage };
