/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addSku,
  addSkuFailed,
  addSkuSuccess,
  getSku,
  getSkuDetail,
  getSkuDetailSuccess,
  getSkuDetailFailed,
  getSkuFailed,
  getSkuSuccess,
  updateSkuStatus,
  updateSkuStatusFailed,
  updateSkuStatusSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getSkuSaga(action: ReturnType<typeof getSku>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getSku, {
      params: {
        page: q ? 1 : page,
        limit,
        q,
      },
    });
    yield put(getSkuSuccess(result.data.sku));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getSkuFailed(err));
  }
}
// ----------------------------------------
function* getSkuDetailSaga(action: ReturnType<typeof getSkuDetail>): any {
  const { id } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getSkuDetail + id);
    yield put(getSkuDetailSuccess(result.data.sku));
  } catch (err: any) {
    yield put(getSkuDetailFailed(err));
  }
}

// ----------------------------------------

function* addSkuSaga(action: ReturnType<typeof addSku>): any {
  try {
    const {
      id,
      skuId,
      attributes = {},
      productImage,
      desc,
      productId,
      name,
      productName,
      attributesObject,
    } = action.payload;
    const { data } = yield select((store: RootState) => store.skuSlice.skuDetail);

    const mappedAttributes = Object.fromEntries(
      Object.entries(attributes).map(([key, value]) => [key.replace('att_', ''), value])
    );

    const formData = new FormData();

    if (!id) {
      formData.append('skuId', skuId);
    }
    formData.append('name', name);
    formData.append('type', 'product');
    productId && formData.append('productId', productId + '');
    formData.append('desc', desc + '');
    formData.append('attributes', JSON.stringify(mappedAttributes));

    if (productImage && productImage.length > 0) {
      [...productImage].forEach((image: any, index: number) => {
        formData.append(`images`, image);
      });
    }
    if (id) {
      formData.append('status', 'active');
    }

    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateSku + id, formData);
    } else {
      result = yield call(request.post, endpoints.addSku, formData);
    }

    yield put(addSkuSuccess(result.data));
    if (id) {
      const updatedAttribute: any = [];

      Object.entries(attributes).forEach(([key, value]) => {
        const filteredObject = attributesObject?.filter(
          (attr: any) => Number(attr.id) === Number(key)
        );

        const updatedObject = {
          attribute: { id: key, name: filteredObject?.[0]?.name ?? '' },
          attributeId: key,
          attributeValue: value,
          skuId: id,
        };
        updatedAttribute.push(updatedObject);
      });

      yield put(
        getSkuDetailSuccess({
          ...data,
          ...result.data.sku,
          skuAttributes: updatedAttribute ?? [],
          products: { ...data.products, name: productName },
        })
      );
    }
  } catch (err: any) {
    yield put(addSkuFailed(err));
  }
}

// ----------------------------------------

function* updateSkuStatusSaga(action: ReturnType<typeof updateSkuStatus>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.skuSlice.skuDetail);
    const result = yield call(request.patch, endpoints.updateSkuStatus + id);
    yield put(updateSkuStatusSuccess(result.data.message));
    yield put(
      getSkuDetailSuccess({
        ...data,
        active: !data.active,
      })
    );
  } catch (err: any) {
    yield put(updateSkuStatusFailed(err));
  }
}

// ----------------------------------------

function* skuSagas() {
  yield all([
    takeLatest([getSku, addSkuSuccess], getSkuSaga),
    takeLatest(addSku, addSkuSaga),
    takeLatest(updateSkuStatus, updateSkuStatusSaga),
    takeLatest(getSkuDetail, getSkuDetailSaga),
  ]);
}

export { skuSagas };
