import {
  Dialog,
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  Grid,
  Divider,
} from '@mui/material';

import { Carousel } from './carousel';
import { Iconify } from '../iconify';

const CarouselPreview = ({ isPreviewOpen, handleClosePreview, details }: any) => {
  return (
    <Dialog
      open={isPreviewOpen}
      onClose={handleClosePreview}
      maxWidth={false}
      scroll="paper"
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 430,
          height: '100%',
          maxHeight: 932,
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
          border: '1px solid #ccc',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <AppBar position="static" sx={{ bgcolor: '#FFA500', position: 'sticky', top: 0, zIndex: 1 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back">
            <Iconify width={25} icon="mdi:arrow-left" style={{ color: 'white' }} />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            Product Details
          </Typography>
          <IconButton edge="end" color="inherit" aria-label="cart">
            <Iconify
              width={25}
              icon="mdi:cart-outline"
              style={{ color: 'white', strokeWidth: 10 }}
            />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Box
        sx={{
          flexGrow: 1,
          overflowY: 'auto',
        }}
        mt={2}
      >
        <Box
          sx={{
            width: '100%',
            height: '250px',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box height="300" sx={{ width: 450, alignSelf: { xs: 'center' }, objectFit: 'contain' }}>
            <Carousel
              component="img"
              alt="image"
              height="250"
              sx={{
                width: '100%',
                objectFit: 'contain',
                cursor: 'pointer',
              }}
              details={details ?? '/assets/logo/logoOnly.png'}
              isPreviewMode={true}
            />
          </Box>
        </Box>

        <Box sx={{ p: 2 }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 550, fontFamily: 'Outfit, sans-serif' }}>
            {details?.name}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              mt: 1,
              gap: 1,
            }}
          >
            <Typography sx={{ color: '#FFA500', fontWeight: 'bold' }} variant="h5">
              AED26
            </Typography>
            <Typography
              variant="body2"
              sx={{ textDecoration: 'line-through', color: 'gray', ml: 1 }}
            >
              AED45
            </Typography>
            <Typography variant="body2" sx={{ color: 'green', ml: 1 }}>
              42.22% OFF
            </Typography>
          </Box>

          <Box sx={{ mt: 2, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ mr: 2 }}>Quantity</Typography>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #FFA500',
                borderRadius: '10px',
                overflow: 'hidden',
                width: 'fit-content',
                py: 0.4,
              }}
            >
              <Button
                sx={{
                  minWidth: 0,
                  padding: '0 8px',
                }}
              >
                <Iconify icon="mdi:minus" />
              </Button>
              <Typography
                sx={{
                  textAlign: 'center',
                  width: '20px',
                }}
              >
                1
              </Typography>
              <Button
                sx={{
                  minWidth: 0,
                  padding: '0 8px',
                }}
              >
                <Iconify icon="mdi:plus" />
              </Button>
            </Box>
          </Box>

          <Grid container spacing={2} sx={{ mt: 3 }}>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" sx={{ bgcolor: '#FFA500', color: '#fff' }}>
                Add to Cart
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth variant="contained" sx={{ bgcolor: '#007BFF', color: '#fff' }}>
                Buy Now
              </Button>
            </Grid>
          </Grid>
        </Box>

        <Divider sx={{ my: 2 }} />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Highlights
          </Typography>
          <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
            {details?.desc}
          </Typography>
        </Box>
        {details?.skuAttributes?.[0] && (
          <Box sx={{ px: 2, pb: 2 }}>
            {details.skuAttributes?.map(
              (skuAtt: { id: string; attributeValue: string; attribute: { name: string } }) => (
                <Box key={skuAtt.id} mb={1}>
                  <Box>
                    <Typography variant="h6" sx={{ mb: -1 }}>
                      {skuAtt?.attribute?.name}
                    </Typography>
                  </Box>
                  <Box justifySelf={'start'} flex={1}>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {skuAtt.attributeValue}
                    </Typography>
                  </Box>
                </Box>
              )
            )}
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

export { CarouselPreview };
