import { useState } from 'react';
import { Box, Button, CardMedia, CircularProgress, Stack, useTheme } from '@mui/material';

import { Iconify } from '../iconify/iconify';
import { CarouselPreview } from './carousel-preview';

const Carousel = ({ details, isPreviewMode }: any) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const theme = useTheme();

  const handleNext = () => {
    setIsLoading(true);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % details?.images.length);
  };

  const handlePrev = () => {
    setIsLoading(true);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    setCurrentIndex(
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      (prevIndex) => (prevIndex - 1 + details?.images.length) % details?.images.length
    );
  };

  const handleImageClick = () => {
    setIsPreviewOpen(true);
  };

  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  const handleImageError = () => {
    console.error('Image failed to load');
    setIsLoading(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          ml: 1,
        }}
      >
        <Stack>
          <Button
            onClick={handlePrev}
            sx={{
              transform: 'translateY(-50%)',
              zIndex: 2,
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '50%',
              // padding: 0,
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[3],
              '&:hover': {
                backgroundColor: theme.palette.background.paper,
              },
            }}
          >
            <Iconify icon="lucide:chevron-left" />
          </Button>
        </Stack>

        <Stack
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'center',
            px: 2,
          }}
        >
          <Box
            sx={{
              overflow: 'hidden',
              width: '100%',
              height: '300px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {isLoading && <CircularProgress size={40} />}
            <CardMedia
              component="img"
              height={250}
              sx={{
                width: '100%',
                alignSelf: 'center',
                objectFit: 'contain',
                cursor: 'pointer',
                resize: 'contain',
                display: isLoading ? 'none' : 'block',
              }}
              loading="lazy"
              image={details?.images[currentIndex] ?? '/assets/logo/logoOnly.png'}
              onClick={isPreviewMode ? undefined : handleImageClick}
              onLoad={handleImageLoad}
              onError={handleImageError}
            />
          </Box>
        </Stack>

        <Stack>
          <Button
            onClick={handleNext}
            sx={{
              transform: 'translateY(-50%)',
              zIndex: 2,
              minWidth: '40px',
              minHeight: '40px',
              borderRadius: '50%',
              padding: 0,
              backgroundColor: theme.palette.background.default,
              boxShadow: theme.shadows[3],
              '&:hover': {
                backgroundColor: theme.palette.background.paper,
              },
            }}
          >
            <Iconify icon="ci:chevron-right" />
          </Button>
        </Stack>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          mt: -3,
        }}
      >
        {details?.images &&
          details?.images?.length > 0 &&
          details?.images.map((_: any, index: any) => (
            <Box
              key={index}
              sx={{
                width: '10px',
                height: '10px',
                borderRadius: '50%',
                backgroundColor:
                  index === currentIndex ? theme.palette.primary.main : theme.palette.grey[400],
                marginX: 0.5,
                cursor: 'pointer',
                transition: 'background-color 0.3s',
              }}
              onClick={() => {
                setCurrentIndex(index);
              }}
            />
          ))}
      </Box>
      <CarouselPreview
        isPreviewOpen={isPreviewOpen}
        handleClosePreview={() => {
          setIsPreviewOpen(false);
        }}
        details={details}
      />
    </Box>
  );
};

export { Carousel };
