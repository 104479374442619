import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { ISlotManagement } from '../types';
import { SlotManagementRow } from './slot-management-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'slotName', label: 'Slot Name', align: 'center', sortable: false },
  { id: 'maxOrders', label: 'Max Orders', align: 'center', sortable: false },
  { id: 'slotCutOff', label: 'Slot CutOff', align: 'center', sortable: false },
  { id: 'duration', label: 'Duration', align: 'center', sortable: false },
  { id: 'deliveryStartTime', label: 'Delivery Start Time', align: 'center', sortable: false },
  { id: 'deliveryEndTime', label: 'Delivery End Time', align: 'center', sortable: false },
  { id: 'deliveryCharges', label: 'Delivery Charges', align: 'center', sortable: false },
  { id: 'type', label: 'Type', align: 'left', sortable: false },
  { id: 'city', label: 'City', align: 'left', sortable: false },
  { id: 'dayStatus', label: 'Day Status', align: 'center', sortable: false },
  { id: 'status', label: 'Status', align: 'center', sortable: false },
  { id: 'activeInactive', label: 'Active/Inactive', align: 'center', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: any;
  loading: boolean;
  selectedStatus: any;
  selectedSlotType: any;
  count: number;
}

const SlotManagementList = ({
  count,
  tableProps,
  rows,
  loading,
  selectedStatus,
  selectedSlotType,
}: Props) => {
  const navigation = useNavigate();
  const handleSelect = (data: any) => {
    const id = data.id;
    navigation(PATH_DASHBOARD.slotManagementDetail, { state: { id } });
  };

  const filteredRows = rows?.filter((row: { active: any; slotType: string }) => {
    const isActive =
      selectedStatus === 'active'
        ? row.active ?? true
        : selectedStatus === 'inActive'
          ? !row.active
          : true;
    const isMatchingSlotType = selectedSlotType
      ? row.slotType.toLowerCase() === selectedSlotType.toLowerCase()
      : true;

    return isActive && isMatchingSlotType;
  });

  return (
    <PaginatedTable<ISlotManagement>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={filteredRows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <SlotManagementRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            handleSelect(row);
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default SlotManagementList;
