import { useDispatch } from 'react-redux';
import { MenuItem, Select, Stack, Typography } from '@mui/material';

import { CustomAvatar } from '@app/components/custom-avatar';
import { TableCellCustom } from '@app/components/table';
import { useRequestState } from '@app/hooks';
import { IUser } from '../types';
import { updateUserStatus } from '../slice';
import { selectUpdateUserStatusState } from '../selectors';

interface Props {
  row: IUser;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const UserRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  const handleChange = (id: any) => {
    dispatch(updateUserStatus({ id }));
  };

  useRequestState({
    stateSelector: selectUpdateUserStatusState,
    successMessageShown: true,
    errorShown: true,
  });

  function convertTimeToAMPM(timeString: any) {
    const [hours, minutes] = timeString.split(':');
    let period = 'AM';

    let hoursInt = parseInt(hours, 10);
    if (hoursInt >= 12) {
      period = 'PM';
      if (hoursInt > 12) {
        hoursInt -= 12;
      }
    }

    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    return `${hoursInt}:${minutes} ${period}`;
  }
  let concatenatedTime = 'NA';
  if (row.startTime === null || row.endTime === null) {
    concatenatedTime = 'NA';
  } else {
    const startTimeAMPM = convertTimeToAMPM(row.startTime);
    const endTimeAMPM = convertTimeToAMPM(row.endTime);

    concatenatedTime = `${startTimeAMPM}-${endTimeAMPM}`;
  }
  return (
    <>
      <TableCellCustom onClick={onSelectRow} align="left">
        <Typography variant="body2">{row?.id || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow}>
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <CustomAvatar
            src={row.profileImage}
            name={`${row.firstName} ${row.lastName}`}
            color="primary"
          />
          <Typography variant="body2">
            {row?.firstName} {row?.lastName}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {(row?.userType === 'VU' && 'Vendor') ||
            (row?.userType === 'AA' && 'Admin') ||
            (row?.userType === 'NU' && 'Normal') ||
            (row?.userType === 'SA' && 'Super Admin')}
        </Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" sx={{ width: '100px' }}>
        <Typography variant="body2">{row?.vendorType}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.email || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {row?.phone ||
            (row?.addresses &&
              (row.addresses[0]?.countryCode ?? '') + (row.addresses[0]?.mobileNumber ?? '')) ||
            'NA'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom flex onClick={onSelectRow} align="center">
        <Typography variant="body2">{concatenatedTime}</Typography>
      </TableCellCustom>

      <TableCellCustom width={150} align="center">
        <Select
          value={row?.active ? 'Active' : 'Inactive'}
          onChange={() => {
            handleChange(row?.id);
          }}
          sx={{ height: 25, width: 100, fontSize: 13 }}
        >
          <MenuItem value={'Active'}>Active</MenuItem>
          <MenuItem value={'Inactive'}>InActive</MenuItem>
        </Select>
      </TableCellCustom>
    </>
  );
};

export { UserRow };
