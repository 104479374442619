import { useEffect, useState } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  Checkbox,
  LinearProgress,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { SearchBar } from '@app/components';
import { TruncatedTypography } from '@app/components/typography';
import { useRequestState } from '@app/hooks';
import { getCustomer } from '@app/modules/customers';
import { selectCustomerState } from '@app/modules/customers/selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  handleSubmit?: any;
  selectedCustomers: any;
}

const SelectCustomerDialog = ({ open, onClose, handleSubmit, selectedCustomers }: Props) => {
  const dispatch = useDispatch();

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCustomer({ page: 1, q: query }));
    } else {
      dispatch(getCustomer({ page: 1 }));
    }
  }, [dispatch, query]);

  const { data, loading } = useRequestState({
    stateSelector: selectCustomerState,
  });

  const [selectedItemIds, setSelectedItemIds] = useState<any>([]);
  const [page, setPage] = useState<number>(2);
  const [customer, setCustomer] = useState<any>([]);

  useEffect(() => {
    if (selectedCustomers && selectedCustomers?.length > 0) {
      setSelectedItemIds(selectedCustomers);
    }
  }, [selectedCustomers]);

  const handleCheckboxChange = (itemId: number, firstName: string) => {
    setSelectedItemIds((prevSelectedIds: any) => {
      const exists = prevSelectedIds.some((item: any) => item.id === itemId);
      if (exists) {
        return prevSelectedIds.filter((item: any) => item.id !== itemId);
      } else {
        return [...prevSelectedIds, { id: itemId, firstName }];
      }
    });
  };

  useEffect(() => {
    if (data) {
      if (!query) {
        setCustomer((prevCustomer: any) => [
          ...prevCustomer,
          ...data.filter(
            (customerData: any) =>
              !prevCustomer?.some((prevCus: any) => prevCus.id === customerData.id)
          ),
        ]);
      } else {
        setCustomer(data);
      }
    }
  }, [data, query]);

  const handleCallApi = async () => {
    if (loading) return;
    const nextPage = page + 1;
    setPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getCustomer({ page, q: query }));
      resolve();
    });
  };

  const [prevScrollTop, setPrevScrollTop] = useState(0);

  const handleScroll = async (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
    const bottomThreshold = scrollHeight - clientHeight - 10;

    if (scrollTop > bottomThreshold && scrollTop > prevScrollTop) {
      await handleCallApi();
    }
    setPrevScrollTop(scrollTop);
  };

  return (
    <>
      <Dialog open={open} fullWidth>
        <Header variant="dialog" title={Strings.field.selectCustomer} onClose={onClose} />
        <Divider />
        <SearchBar
          sx={{ padding: '2% 2% 1% 2%' }}
          onChange={handleSearchChange}
          placeholder={Strings.common.search}
        />
        {loading && <LinearProgress sx={{ mt: 2 }} />}
        <DialogContent onScroll={handleScroll}>
          <Stack spacing={2} my={2}>
            {customer?.map((item: any, index: number) => {
              return (
                <>
                  <Stack display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Stack
                      sx={{ width: '170px' }}
                      key={index}
                      onClick={() => {
                        handleCheckboxChange(item.id, item.firstName);
                      }}
                    >
                      <TruncatedTypography text={item.id} lines={1} />
                    </Stack>
                    <Stack
                      sx={{
                        width: '300px',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                      key={index}
                      onClick={() => {
                        handleCheckboxChange(item.id, item.firstName);
                      }}
                    >
                      <TruncatedTypography
                        text={item?.firstName ? item.firstName : 'N/A'}
                        lines={1}
                      />
                    </Stack>
                    <Checkbox
                      checked={selectedItemIds.some(
                        (selectedItem: any) => selectedItem.id === item.id
                      )}
                      onChange={() => {
                        handleCheckboxChange(item.id, item.firstName);
                      }}
                    />
                  </Stack>
                </>
              );
            })}
          </Stack>
        </DialogContent>

        <Divider />

        <DialogActions>
          <Button variant="outlined" color="inherit" onClick={onClose}>
            {Strings.button.cancel}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            onClick={() => {
              handleSubmit(selectedItemIds);
            }}
          >
            {Strings.button.save}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export { SelectCustomerDialog };
