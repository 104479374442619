import { Checkbox, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { TableCellCustom } from '@app/components/table';
import { StatusTag } from '@app/components/status-tag/StatusTag';
import { DayTag } from '@app/components/day-tag';
import { ISlotManagement } from '../types';
import { updateSlotStatus } from '@app/modules/slot-management-detail/slice';
import { useRequestState } from '@app/hooks';
import { selectUpdateSlotStatusState } from '@app/modules/slot-management-detail/selectors';

interface Props {
  row: ISlotManagement;
  selected: boolean;
  onSelectRow: VoidFunction;
}

const SlotManagementRow = (props: Props) => {
  const { row, onSelectRow } = props;
  const dispatch = useDispatch();

  const [isChecked, setIsChecked] = useState(row.active);

  useRequestState({
    stateSelector: selectUpdateSlotStatusState,
    successMessageShown: true,
  });

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    dispatch(updateSlotStatus({ id: row.id }));
  };
  return (
    <>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack style={{ flexDirection: 'row', alignItems: 'center', gap: '9px' }}>
          <Typography variant="body2">{row?.name || 'NA'}</Typography>
        </Stack>
      </TableCellCustom>

      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.capacity || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Stack>
          <Typography variant="body2">
            {row?.orderCutOff ? dayjs(`2022-01-01 ${row.orderCutOff}`).format('hh:mm a') : 'NA'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.duration || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Stack direction={'row'} width={60}>
          <Typography variant="body2">
            {row?.deliveryStartTime
              ? dayjs(`2022-01-01 ${row.deliveryStartTime}`).format('hh:mm a')
              : 'NA'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center" fullWidth>
        <Stack direction={'row'} width={60}>
          <Typography variant="body2">
            {row?.deliveryEndTime
              ? dayjs(`2022-01-01 ${row.deliveryEndTime}`).format('hh:mm a')
              : 'NA'}
          </Typography>
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.deliveryCharge || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">{row?.slotType || 'NA'}</Typography>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <Typography variant="body2">
          {row?.city === 'dubai' ? 'Dubai' : row?.city === 'abu dhabi' ? 'Abu Dhabi' : 'Sharjah'}
        </Typography>
      </TableCellCustom>
      <TableCellCustom fullWidth align="center">
        <Stack direction={'row'} width={200}>
          <DayTag selectedDays={row?.availableDays} />
        </Stack>
      </TableCellCustom>
      <TableCellCustom onClick={onSelectRow} align="center">
        <StatusTag active={row.active} name={row.active ? 'Active' : 'InActive'} />
      </TableCellCustom>
      <TableCellCustom align="center">
        <Checkbox key={row.id} checked={isChecked} onChange={handleCheckboxChange} />
      </TableCellCustom>
    </>
  );
};

export { SlotManagementRow };
