/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { useCallback, useEffect, useState } from 'react';
import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { get, debounce } from 'lodash';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { getCategory, selectCategoryState } from '@app/modules/categories';
import { FormProvider, RHFTextField, RHFUploadAvatar, RHFAutocomplete } from '@app/components';
import { IAddProductSchema, IAddCategory, IProduct } from '../types';
import { addProductSchema } from '../validator';
import { addProduct } from '../slice';
import { IMAGE_BASE_URL } from '@app/configs';
import { useRequestState } from '@app/hooks';
import { selectAddProductState } from '../selectors';
import { useTable } from '@app/components/table';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IProduct;
}

const AddProduct = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const [query, setQuery] = useState('');

  const [isImageUploaded, setIsImageUploaded] = useState(false);
  const [categoryPage, setCategoryPage] = useState(2);
  const [category, setCategory] = useState<IAddCategory[]>([]);
  const [initialValues, setInitialValues] = useState<IAddProductSchema | null>(null);

  const methods = useForm<IAddProductSchema>({
    resolver: yupResolver(addProductSchema) as any,
    defaultValues: {
      name: get(row, 'name', ''),
      desc: get(row, 'description', ''),
      image: row?.images ? IMAGE_BASE_URL + row.images : '',
      categoryId: row?.category?.name,
      petType: row?.pet_type ? row?.pet_type.charAt(0).toUpperCase() + row?.pet_type.slice(1) : '',
    },
  });

  useEffect(() => {
    methods.reset({
      name: get(row, 'name', ''),
      desc: get(row, 'description', ''),
      petType: row?.pet_type ? row?.pet_type.charAt(0).toUpperCase() + row?.pet_type.slice(1) : '',
      image: row?.images ? IMAGE_BASE_URL + row.images : '',
      categoryId: row?.category?.name,
    });
    if (row) {
      setInitialValues(methods.getValues());
    }
  }, [row, methods, open]);

  const page = tableProps.page;

  const handleCategoryInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setQuery(value);
  }, 300);

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCategory({ page, q: query }));
    } else {
      dispatch(getCategory({ page }));
    }
  }, [dispatch, page, query]);

  const onSubmit = (form: IAddProductSchema) => {
    let category: any = methods.getValues('categoryId');
    if (typeof category !== 'object' || !category.id) {
      const selectedCategory = categoryState?.find((item: any) => item.name === category);
      if (selectedCategory) {
        category = {
          id: selectedCategory.id,
          label: selectedCategory.name,
        };
      }
    }
    dispatch(
      addProduct({
        id: row?.id,
        ...form,
        categoryId: category.id,
        categoryName: category.label,
        image: methods.getValues('image'),
      })
    );
  };

  const handleCallCategoryApi = async () => {
    const nextPage = categoryPage + 1;
    setCategoryPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getCategory({ page: categoryPage }));
      resolve();
    });
  };

  const { data: categoryState, loading: categoryStateloading } = useRequestState({
    stateSelector: selectCategoryState,
  });

  const { loading } = useRequestState({
    stateSelector: selectAddProductState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );

  useEffect(() => {
    if (categoryState) {
      if (!query) {
        setCategory((prevCategories) => [
          ...prevCategories,
          ...categoryState.filter(
            (category: { id: number }) =>
              !prevCategories.some((prevCategory) => prevCategory.id === category.id)
          ),
        ]);
      } else {
        setCategory(categoryState);
      }
    }
  }, [categoryState, query, setCategory]);

  const petTypes = [
    { id: 'cat', value: Strings.selects.cat },
    { id: 'dog', value: Strings.selects.dog },
    { id: 'kitten', value: Strings.selects.kitten },
    { id: 'puppy', value: Strings.selects.puppy },
  ];

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={
              row?.id !== undefined ? Strings.pageTitle.editProduct : Strings.button.addProduct
            }
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="name"
                required
                label={Strings.field.name}
                fullWidth
                variant="outlined"
              />

              <RHFTextField
                name="desc"
                required
                label={Strings.field.description}
                fullWidth
                variant="outlined"
                multiline
                minRows={2}
              />

              <RHFAutocomplete
                name="categoryId"
                label={Strings.field.category}
                options={(category || []).map((category) => ({
                  label: category.name,
                  id: category.id,
                }))}
                onInputChange={handleCategoryInputChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: IAddCategory, value: IAddCategory) =>
                  option.id === value.id
                }
                callApi={handleCallCategoryApi}
                hasMore={categoryState?.length !== 0 && !query}
                loading={categoryStateloading}
              />
              <RHFAutocomplete
                name="petType"
                label={Strings.field.petType}
                options={petTypes.map((item) => ({
                  label: item.value,
                  id: item.id,
                }))}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                onChange={(_, newValue) => {
                  if (newValue === null) {
                    methods.setValue('petType', '');
                  } else if (newValue && typeof newValue === 'object' && 'id' in newValue) {
                    methods.setValue(
                      'petType',
                      newValue.id.charAt(0).toUpperCase() + newValue.id.slice(1)
                    );
                  }
                }}
              />
              <RHFUploadAvatar
                name={'image'}
                onDrop={handleDrop}
                sx={{
                  borderRadius: '10px',
                  width: 115,
                  height: 115,
                  border: 'none',
                }}
                placeholderSx={{
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  border:
                    isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                }}
              />
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues ?? {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                )
              }
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddProduct;
