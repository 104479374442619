import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import dayjs, { Dayjs } from 'dayjs';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { useDialog, useRequestState, useSearch } from '@app/hooks';
import DashboardList from './dashboard-list';
import { getOrder } from '@app/modules/order-management/slice';
import { selectOrderState } from '@app/modules/order-management/selectors';
import { usePageCount } from '@app/hooks/use-page-count';
import { calculateTotalDiscount, calculateTotalPrice, isActiveSlot } from '@app/utils';
import { IDeliverySlot } from '@app/modules/order-management';

const TABLE_HEAD_CSV = [
  { key: 'orderIdInt', label: 'Order-Id', align: 'left' },
  { key: 'orderId', label: 'Order Id', align: 'left' },
  { key: 'customer', label: 'Customer', align: 'center', sortable: false },
  { key: 'customerId', label: 'Customer Id', align: 'center', sortable: false },
  { key: 'orderStatus', label: 'Order Status', align: 'center', sortable: false },
  { key: 'userPreference', label: 'User Preference', align: 'center', sortable: false },
  { key: 'address', label: 'Address', align: 'left', sortable: false },
  { key: 'skuId', label: 'Sku Id', align: 'left', sortable: false },
  { key: 'skuName', label: 'Sku Name', align: 'left', sortable: false },
  { key: 'vendorName', label: 'Vendor Name', align: 'left', sortable: false },
  { key: 'paymentMode', label: 'Payment Mode', align: 'left', sortable: false },
  { key: 'qtyOrdered', label: 'Qty Ordered', align: 'left', sortable: false },
  { key: 'ourPrice', label: 'Our Price', align: 'left', sortable: false },
  { key: 'discount', label: 'Discount', align: 'left', sortable: false },
  { key: 'discountAmount', label: 'Discount Amount', align: 'left', sortable: false },
  { key: 'discountedPrice', label: 'Discounted Price', align: 'left', sortable: false },
  { key: 'deliveryCharge', label: 'Delivery Charge', align: 'center', sortable: false },
  { key: 'voucherDiscount', label: 'Total Voucher Discount', align: 'center', sortable: false },
  { key: 'finalPrice', label: 'Total Paid Amount', align: 'center', sortable: false },
  { key: 'deliverySlot', label: 'Delivery Slot', align: 'left', sortable: false },
  { key: 'orderDate', label: 'Order Date', align: 'left', sortable: false },
  { key: 'deliveryDate', label: 'Delivery Date', align: 'left', sortable: false },
];

const DashboardPage = () => {
  const tableProps = useTable();
  const dashboardDialog = useDialog();
  const dispatch = useDispatch();
  const { page, rowsPerPage } = tableProps;
  const { count } = usePageCount(getOrder);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getOrder({ page, q: query }));
    } else {
      dispatch(getOrder({ page, limit: rowsPerPage }));
    }
  }, [dispatch, page, query, rowsPerPage]);

  const { data, loading } = useRequestState({
    stateSelector: selectOrderState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const searchProps = useSearch({
    data,
    fields: ['name', 'orderStatus'],
  });

  const flattenData = (data: any) => {
    const serializeData: any = [];

    data?.forEach((elem: any) => {
      const totalPrice: any = calculateTotalPrice(elem?.orderProducts);
      const totalDiscount: any = calculateTotalDiscount(elem?.orderProducts);
      const activeSlot: IDeliverySlot = isActiveSlot(elem?.orderSlots);
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      const customerAddress = `${elem?.customerAddress?.city}, ${elem?.customerAddress?.country}`;
      const deliveryDate = activeSlot?.deliveryDate
        ? dayjs(activeSlot?.deliveryDate).format('YYYY-MM-DD')
        : 'NA';
      const deliverySlot =
        activeSlot?.slotType === 'normal'
          ? `${dayjs(`2022-01-01 ${activeSlot?.deliveryStartTime}`).format('hh:mm A')} - ${dayjs(
              `2022-01-01 ${activeSlot?.deliveryEndTime}`
            ).format('hh:mm A')}`
          : `Express (${activeSlot?.duration} min)`;

      const finalPrice =
        totalPrice - totalDiscount + activeSlot?.deliveryCharge - elem?.totalCouponDiscount;

      const orderDate = dayjs(
        elem?.orderStatus?.find(
          (item: { orderStatus: string }) => item?.orderStatus === Strings.status.confirmed
        )?.createdAt
      ).format('YYYY-MM-DD');

      const latestOrderStatus = elem?.orderStatus
        ?.slice()
        ?.sort(
          (a: { createdAt: Dayjs }, b: { createdAt: Dayjs }) =>
            dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf()
        )[0]?.orderStatus;

      elem?.orderProducts.forEach((pro: any, index: any) => {
        serializeData.push({
          // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          customer: `${elem?.customerAddress?.name} ${elem?.customerAddress?.mobileNumber}` || '',
          customerId: elem?.customerAddress?.userId || 'N/A',
          orderStatus: index === 0 ? latestOrderStatus : '',
          orderIdInt: index === 0 ? elem?.id : '',
          userPreference: index === 0 ? elem?.userPreferences : '',
          orderId: elem?.orderId ?? '',
          address: customerAddress ?? '',
          skuId: pro?.sku?.id,
          skuName: pro?.sku?.name,
          vendorName: pro?.vendorAddress?.name,
          paymentMode: index === 0 ? elem?.paymentMode : '',
          qtyOrdered: pro?.quantity,
          ourPrice: pro?.ourPrice?.toFixed(2),
          discount: `${(((pro?.ourPrice - pro.discountPrice) / pro?.ourPrice) * 100).toFixed(2)} %`,
          discountAmount: (pro?.ourPrice - pro.discountPrice).toFixed(2),
          discountedPrice: pro?.discountPrice.toFixed(2),
          voucherDiscount: index === 0 ? elem?.totalCouponDiscount?.toFixed(2) : '',
          finalPrice: index === 0 ? finalPrice?.toFixed(2) : '',
          deliverySlot: index === 0 ? deliverySlot : '',
          deliveryCharge: index === 0 ? activeSlot?.deliveryCharge : '',
          deliveryDate: index === 0 ? deliveryDate : '',
          orderDate: index === 0 ? orderDate : '',
        });
      });
    });
    return serializeData;
  };

  return (
    <>
      <Header
        title={Strings.pageTitle.dashboard}
        helperText={''}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        onClickActionButton={dashboardDialog.show}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'dashboard-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
        orientation={'landscape'}
      />

      <DashboardList
        loading={loading}
        tableProps={tableProps}
        rows={searchProps.filteredRows}
        count={count}
      />
    </>
  );
};

export { DashboardPage };
