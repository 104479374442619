import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { PromotionList } from './promotion-list';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { useRequestState } from '@app/hooks';
import { getAllPromotion } from '../slice';
import { selectGetAllPromotion } from '../selector';
import { IVoucher } from '../types';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'code', label: 'Code', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'description', label: 'Description', align: 'left' },
  { key: 'voucherType', label: 'Promotion Type', align: 'center' },
  { key: 'discountType', label: 'Discount Type', align: 'center' },
  { key: 'category', label: 'Category', align: 'center' },
  { key: 'country', label: 'Country', align: 'center' },
  { key: 'currency', label: 'Currency', align: 'center' },
  { key: 'totalUsageLimit', label: 'Total Usage', align: 'center' },
  { key: 'totalUses', label: 'Total Used', align: 'center', sortable: false },
  { key: 'remainingUsage', label: 'Remaining Usage', align: 'center', sortable: false },
  { key: 'usagePerUserLimit', label: 'Usage/Person', align: 'center' },
  { key: 'maxDiscountAmount', label: 'Maximum Discount', align: 'center' },
  { key: 'minimumOrderValue', label: 'MOV', align: 'center' },
  { key: 'startDate', label: 'Start Date', align: 'center' },
  { key: 'endDate', label: 'End Date', align: 'center' },
  { key: 'createdBy', label: 'Created By', align: 'center' },
  { key: 'createdOn', label: 'Created On', align: 'center' },
  { key: 'status', label: 'Status', align: 'center' },
];

const PromotionListPage = () => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const navigation = useNavigate();
  const { page, rowsPerPage } = tableProps;
  const { count } = usePageCount(getAllPromotion);

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getAllPromotion({ page, q: query }));
    } else {
      dispatch(getAllPromotion({ page, limit: rowsPerPage }));
    }
  }, [dispatch, page, query, rowsPerPage]);

  const { data, loading } = useRequestState({
    stateSelector: selectGetAllPromotion,
    errorShown: true,
  });

  const flattenData = (data: IVoucher[]) => {
    const serializeData = data?.map((elem: IVoucher) => {
      return {
        ...elem,
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        createdBy: `${elem?.user?.firstName ?? ''} ${elem?.user?.lastName ?? ''}`.trim() || 'N/A',
        createdOn: elem?.createdAt ? dayjs(elem?.createdAt).format('DD/MM/YY hh:mm A') : 'N/A',
        startDate: elem?.startDate ? dayjs(elem?.startDate).format('DD/MM/YYYY') : 'N/A',
        endDate: elem?.endDate ? dayjs(elem?.endDate).format('DD/MM/YYYY') : 'N/A',
        remainingUsage: (elem?.totalUsageLimit ?? 0) - (elem?.totalUses ?? 0),
      };
    });
    return serializeData;
  };

  const handleSelect = () => {
    navigation(PATH_DASHBOARD.addPromotion);
  };
  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };
  return (
    <>
      <Header
        title={Strings.pageTitle.promotions}
        helperText={''}
        actionButtonTitle={Strings.button.addPromotion}
        onClickActionButton={() => {
          handleSelect();
        }}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        data={flattenData(data)}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'promotion-list.csv'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
        orientation={'landscape'}
      />
      <PromotionList loading={loading} tableProps={tableProps} rows={data} count={count} />
    </>
  );
};

export { PromotionListPage };
