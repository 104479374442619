import { useNavigate } from 'react-router-dom';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IOrderList } from '../types';
import { OrderRow } from './order-row';
import { PATH_DASHBOARD } from '@app/routes/paths';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'orderID', label: 'Order Id', align: 'center', sortable: false },
  { id: 'customer', label: 'Customer', align: 'center', sortable: false },
  { id: 'customerID', label: 'Customer Id', align: 'center', sortable: false },
  { id: 'orderStatus', label: 'Order Status', align: 'center' },
  { id: 'userPreference', label: 'User Preference', align: 'center' },
  { id: 'address', label: 'Address', align: 'center', sortable: false },
  { id: 'skuId', label: 'SKU Id', align: 'center', sortable: false },
  { id: 'skuName', label: 'SKU Name', align: 'center', sortable: false },
  { id: 'vendorName', label: 'Vendor Name', align: 'center', sortable: false },
  { id: 'otyOrdered', label: 'Qty Ordered', align: 'center', sortable: false },
  { id: 'paymentMode	', label: 'Payment Mode', align: 'center', sortable: false },
  { id: 'ourPrice', label: 'Our Price', align: 'center', sortable: false },
  { id: 'discount', label: 'Discount', align: 'center', sortable: false },
  { id: 'discountAmount', label: 'Discount Amount', align: 'center', sortable: false },
  { id: 'discountedPrice', label: 'Discounted Price', align: 'center', sortable: false },
  { id: 'voucherDiscount', label: 'Voucher Discount', align: 'center', sortable: false },
  { id: 'finalPrice', label: 'Final Price', align: 'center', sortable: false },
  { id: 'orderDate', label: 'Order Date', align: 'center', sortable: false },
  { id: 'deliveryDate', label: 'Delivery Date', align: 'center', sortable: false },
  { id: 'deliveryCharge', label: 'Delivery Charge', align: 'center', sortable: false },
  { id: 'deliverySlot', label: 'Delivery Slot', align: 'center', sortable: false },
];
interface Props {
  tableProps: TableProps;
  loading: boolean;
  rows?: any[];
  count?: number;
}
const OrderList = ({ tableProps, loading, rows, count }: Props) => {
  const navigation = useNavigate();
  const handleSelect = (id: any) => {
    navigation(PATH_DASHBOARD.orderDetail, { state: { id } });
  };
  return (
    <>
      <PaginatedTable<IOrderList>
        head={TABLE_HEAD}
        data={rows}
        tableProps={tableProps}
        loading={loading}
        isServerSidePaging={true}
        totalCount={count}
      >
        {(row) => (
          <OrderRow
            row={row}
            selected={tableProps.selected.includes(row.id)}
            onSelectRow={() => {
              handleSelect(row.id);
            }}
          />
        )}
      </PaginatedTable>
    </>
  );
};
export default OrderList;
