import { PATH_DASHBOARD } from '@app/routes/paths';
import { Strings } from '@app/constants';
import { SvgColor } from '@app/components/svg-color';
// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 40, height: 24 }} />
);

const ICONS = {
  dogHouse: icon('ic_petHouse'),
  user: icon('ic_user'),
  customers: icon('ic_user'),
  category: icon('ic_category'),
  product: icon('ic_product'),
  attributes: icon('ic_attributes'),
  sku: icon('ic_sku'),
  inventory: icon('ic_inventory'),
  banner: icon('ic_banner'),
  collection: icon('ic_collection'),
  slot: icon('ic_slot'),
  management: icon('ic_management'),
  order: icon('ic_order'),
  voucher: icon('ic_voucher'),
  aboutUs: icon('ic_about'),
  customerPopups: icon('ic_customerPopup'),
};

const navConfig = [
  // Dashboard
  {
    title: Strings.sidebar.dashboard,
    path: PATH_DASHBOARD.app,
    icon: ICONS.dogHouse,
  },
  {
    title: Strings.sidebar.users,
    path: PATH_DASHBOARD.users,
    icon: ICONS.user,
  },
  {
    title: Strings.sidebar.categories,
    path: PATH_DASHBOARD.categories,
    icon: ICONS.category,
  },
  {
    title: Strings.sidebar.product,
    path: PATH_DASHBOARD.product,
    icon: ICONS.product,
  },
  {
    title: Strings.sidebar.attributes,
    path: PATH_DASHBOARD.attribute,
    icon: ICONS.attributes,
  },
  {
    title: Strings.sidebar.sku,
    path: PATH_DASHBOARD.sku,
    icon: ICONS.sku,
  },
  {
    title: Strings.sidebar.inventoryBannerManagement,
    path: PATH_DASHBOARD.inventoryBannerManagement,
    icon: ICONS.inventory,
  },
  {
    title: Strings.sidebar.banner,
    path: PATH_DASHBOARD.banner,
    icon: ICONS.banner,
  },
  {
    title: Strings.sidebar.collection,
    path: PATH_DASHBOARD.collection,
    icon: ICONS.collection,
  },
  {
    title: Strings.sidebar.slotManagement,
    path: PATH_DASHBOARD.slotManagement,
    icon: ICONS.slot,
  },
  {
    title: Strings.sidebar.orderManagement,
    path: PATH_DASHBOARD.order,
    icon: ICONS.order,
  },
  {
    title: Strings.sidebar.customers,
    path: PATH_DASHBOARD.customers,
    icon: ICONS.customers,
  },
  {
    title: Strings.sidebar.aboutUs,
    path: PATH_DASHBOARD.aboutUsDetail,
    icon: ICONS.aboutUs,
  },
  {
    title: Strings.sidebar.promotion,
    path: PATH_DASHBOARD.promotion,
    icon: ICONS.voucher,
  },
  {
    title: Strings.sidebar.customerPopups,
    path: PATH_DASHBOARD.customerPopups,
    icon: ICONS.customerPopups,
  },
];

export default navConfig;
