/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addCollectionFailed,
  addCollectionSuccess,
  getCollection,
  getCollectionFailed,
  getCollectionSuccess,
  addCollection,
  updateCollectionStatus,
  updateCollectionStatusSuccess,
  updateCollectionStatusFailed,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

// ----------------------------------------
function* getCollectionSaga(action: ReturnType<typeof getCollection>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getCollection, {
      params: {
        limit,
        page: q ? 1 : page,
        q,
      },
    });

    yield put(getCollectionSuccess(result?.data?.data));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getCollectionFailed(err));
  }
}

// ----------------------------------------

function* addCollectionSaga(action: ReturnType<typeof addCollection>): any {
  try {
    const { id, name, active, description } = action.payload;
    const formData = new FormData();
    formData.append('name', name);
    formData.append('active', active);
    formData.append('description', description);

    let result;
    if (id) {
      result = yield call(request.put, endpoints.addCollection, formData);
    } else {
      result = yield call(request.post, endpoints.addCollection, {
        name,
        active,
        description,
      });
    }

    yield put(addCollectionSuccess(result.data));
  } catch (err: any) {
    yield put(addCollectionFailed(err));
  }
}

// ----------------------------------------

function* updateCollectionStatusSaga(action: ReturnType<typeof updateCollectionStatus>): any {
  try {
    const result = yield call(request.patch, endpoints.updateCollectionStatus + action.payload);
    yield put(updateCollectionStatusSuccess(result.data.message));
  } catch (err: any) {
    yield put(updateCollectionStatusFailed(err));
  }
}

// ----------------------------------------

function* collectionSagas() {
  yield all([takeLatest([getCollection, addCollectionSuccess], getCollectionSaga)]);
  yield all([takeLatest([updateCollectionStatus], updateCollectionStatusSaga)]);
  yield all([takeLatest([addCollection], addCollectionSaga)]);
}

export { collectionSagas };
