import React from 'react';
import {
  Typography,
  Dialog,
  IconButton,
  AppBar,
  Toolbar,
  useTheme,
  Stack,
  CardMedia,
  Button,
} from '@mui/material';

import { Iconify, SearchBar } from '@app/components';
import { Strings } from '@app/constants';
import { ICustomerPopup } from '../types';

interface BottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
  data: ICustomerPopup;
}

const PopupModal: React.FC<BottomSheetProps> = ({ isOpen, onClose, data }) => {
  const theme = useTheme();

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      maxWidth={false}
      scroll="paper"
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 400,
          height: '100%',
          maxHeight: 932,
          backgroundColor: theme.palette.grey[300],
          borderRadius: '20px',
          overflow: 'hidden',
          boxShadow: '0px 4px 10px rgba(0,0,0,0.2)',
          border: '1px solid #ccc',
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <AppBar position="sticky" sx={{ bgcolor: '#FFA500', top: 0, zIndex: 10 }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" aria-label="back">
            <Iconify
              width={25}
              icon="mdi:arrow-left"
              style={{ color: theme.palette.common.white }}
            />
          </IconButton>
          <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'center' }}>
            {Strings.field.productListing}
          </Typography>
          <IconButton edge="end" color="inherit" aria-label="cart">
            <Iconify
              width={25}
              icon="mdi:cart-outline"
              style={{ color: theme.palette.common.white, strokeWidth: 10 }}
            />
          </IconButton>
        </Toolbar>
        <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <SearchBar
            size="small"
            placeholder="Search"
            sx={{ backgroundColor: theme.palette.common.white, borderRadius: '9px', width: '82%' }}
          />
          <img
            src={`/assets/icons/navbar/ic_filter.svg`}
            alt="back"
            height={'40px'}
            width={'40px'}
          />
        </Toolbar>
      </AppBar>
      <Stack
        sx={{
          flex: 1,
          overflowY: 'auto',
          backgroundColor: theme.palette.common.white,
          borderTopRightRadius: '20px',
          borderTopLeftRadius: '20px',
          p: 2,
          scrollbarWidth: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}
      >
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Stack />
          <Stack
            sx={{
              backgroundColor: theme.palette.grey[400],
              height: 5,
              width: 80,
              borderRadius: '14px',
            }}
          />
          <IconButton edge="end" color="inherit" aria-label="close" onClick={onClose}>
            <Iconify
              width={19}
              icon="mdi:close"
              style={{ color: theme.palette.grey[600], strokeWidth: 10 }}
            />
          </IconButton>
        </Stack>

        <Stack alignItems="center">
          <CardMedia
            component="img"
            alt="Popup Image"
            image={data?.image}
            sx={{ width: 170, height: 170, borderRadius: 2, objectFit: 'contain' }}
          />
        </Stack>

        <Stack sx={{ gap: 1.5, mt: 2 }}>
          <Typography sx={{ fontSize: '18px', color: theme.palette.primary.main }}>
            Introducing Invitations
          </Typography>
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[700] }}>
            {data?.statement1}
          </Typography>
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[700] }}>
            {data?.statement2}
          </Typography>
          <Typography sx={{ fontSize: '14px', color: theme.palette.grey[700] }}>
            {data?.statement3}
          </Typography>
          <Button variant="contained" sx={{ width: '100%' }}>
            Next
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default PopupModal;
