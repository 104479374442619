import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import { useDialog, useRequestState } from '@app/hooks';
import CollectionList from './collection-list';
import AddCollection from './add-collection';
import { getCollection } from '../slice';
import { selectCollectionState } from '../selectors';
import { usePageCount } from '@app/hooks/use-page-count';

const CollectionPage = () => {
  const tableProps = useTable();
  const CollectionDialog = useDialog();
  const dispatch = useDispatch();
  const { count } = usePageCount(getCollection);

  const { page, rowsPerPage } = tableProps;

  const [query, setQuery] = useState('');

  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getCollection({ page, q: query }));
    } else {
      dispatch(getCollection({ page, limit: rowsPerPage }));
    }
  }, [dispatch, page, query, rowsPerPage]);

  const { data, loading } = useRequestState({
    stateSelector: selectCollectionState,
    errorShown: true,
  });

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  return (
    <>
      <Header
        title={Strings.pageTitle.collection}
        helperText={''}
        actionButtonTitle={Strings.button.addCollection}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        onClickActionButton={CollectionDialog.show}
      />

      <CollectionList loading={loading} tableProps={tableProps} rows={data} count={count} />

      {CollectionDialog.visible && <AddCollection open onClose={CollectionDialog.hide} />}
    </>
  );
};

export { CollectionPage };
