/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';
import dayjs from 'dayjs';
import axios from 'axios';

import { request } from '@app/services';
import {
  addUserAddressSuccess,
  getUsers,
  getUsersFailed,
  getUsersSuccess,
  addAdmin,
  addAdminSuccess,
  addAdminFailed,
  addVendor,
  addVendorSuccess,
  addVendorFailed,
  getUserDetail,
  getUserDetailSuccess,
  getUserDetailFailed,
  addMaster,
  addMasterSuccess,
  addMasterFailed,
  getAddress,
  getAddressSuccess,
  getAddressFailed,
  updateUserStatus,
  updateUserStatusSuccess,
  updateUserStatusFailed,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getUsersSaga(action: ReturnType<typeof getUsers>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getAllUsers, {
      params: {
        page: q ? 1 : page,
        limit,
        q,
      },
    });
    yield put(setPageCount({ id: action.type, count: result.data.total }));
    yield put(getUsersSuccess(result.data.users));
  } catch (err: any) {
    yield put(getUsersFailed(err));
  }
}

// ----------------------------------------
function* getUserDetailSaga(action: ReturnType<typeof getUserDetail>): any {
  const { id } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getUserDetail + id);
    yield put(getUserDetailSuccess(result.data.user));
  } catch (err: any) {
    yield put(getUserDetailFailed(err));
  }
}
// ----------------------------------------

function* getAddressSaga(action: ReturnType<typeof getAddress>): any {
  const { q } = action.payload;
  try {
    const response = yield axios.get(
      'https://browse.search.hereapi.com/v1/discover?apiKey=0qbg_EccfaKeKbauglIpuBbhdLA3KXZgcRtbT2xatHw',
      {
        params: {
          q,
          at: '25.0784,55.14871',
          lang: 'en',
          in: 'countryCode:ARE',
        },
      }
    );
    yield put(getAddressSuccess(response.data.items));
  } catch (err: any) {
    yield put(getAddressFailed(err));
  }
}
// ----------------------------------------

function* addVendorSaga(action: ReturnType<typeof addVendor>): any {
  const { data } = yield select((store: RootState) => store.userSlice.userDetail);
  try {
    const {
      name,
      address,
      city,
      country,
      pinCode,
      latitude,
      longitude,
      coverageArea,
      description,
      mobileNumber,
      vendorType,
      email,
      startTime,
      endTime,
      id,
      image,
      slotSkip,
    } = action.payload;

    const slotSkipToStr = String(slotSkip);

    const formData = new FormData();
    formData.append('name', name);
    formData.append('number', mobileNumber);
    formData.append('email', email);
    formData.append('vendorType', vendorType);
    formData.append('startTime', dayjs(startTime).format('HH:mm:ss'));
    formData.append('endTime', dayjs(endTime).format('HH:mm:ss'));
    formData.append('address', address);
    formData.append('coverageArea', coverageArea);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('pinCode', pinCode ?? '');
    formData.append('lat', latitude);
    formData.append('long', longitude);
    formData.append('slotSkip', vendorType === 'MP' ? slotSkipToStr : '0');

    if (description !== undefined) {
      formData.append('description', description);
    }

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    let result;
    if (!id) {
      result = yield call(request.post, endpoints.addVendor, formData);
    } else {
      result = yield call(request.put, endpoints.updateUser + id, formData);
    }
    if (result?.data.status === true) {
      yield put(addVendorSuccess(result.data));
    } else {
      const errorMessage = result?.data.message;
      yield put(addVendorFailed({ message: errorMessage }));
    }
    if (id) {
      yield put(
        getUserDetailSuccess({
          ...data,
          ...result.data.data,
          userType: data.userType,
          firstName: result.data.user.firstName,
          startTime: result.data.user.startTime,
          endTime: result.data.user.endTime,
          addresses: result.data.user.addresses,
          slotSkip: result.data.user.slotSkip,
        })
      );
    }
  } catch (err: any) {
    yield put(addVendorFailed(err));
  }
}
// ----------------------------------------
function* addMasterSaga(action: ReturnType<typeof addMaster>): any {
  try {
    const { data } = yield select((store: RootState) => store.userSlice.userDetail);
    const {
      name,
      address,
      city,
      country,
      pinCode,
      latitude,
      longitude,
      coverageArea,
      description,
      mobileNumber,
      email,
      startTime,
      endTime,
      image,
      id,
    } = action.payload;

    const formData = new FormData();
    formData.append('name', name);
    formData.append('number', mobileNumber);
    formData.append('email', email);
    formData.append('startTime', dayjs(startTime).format('HH:mm:ss'));
    formData.append('endTime', dayjs(endTime).format('HH:mm:ss'));
    formData.append('address', address);
    formData.append('description', description);
    formData.append('coverageArea', coverageArea);
    formData.append('city', city);
    formData.append('country', country);
    formData.append('pinCode', pinCode ?? '');
    formData.append('lat', latitude);
    formData.append('long', longitude);

    if (typeof image === 'object') {
      formData.append('image', image);
    }
    let result;
    if (!id) {
      result = yield call(request.post, endpoints.addMaster, formData);
    } else {
      result = yield call(request.put, endpoints.updateUser + id, formData);
    }

    if (result?.data.status === true) {
      yield put(addMasterSuccess(result.data));
    } else {
      const errorMessage = result?.data.message;
      yield put(addMasterFailed({ message: errorMessage }));
    }

    if (id) {
      yield put(
        getUserDetailSuccess({
          ...data,
          ...result.data.data,
          userType: data.userType,
        })
      );
    }
  } catch (err: any) {
    yield put(addMasterFailed(err));
  }
}
// -----------------------------------------------------

function* addAdminSaga(action: ReturnType<typeof addAdmin>): any {
  try {
    const { data } = yield select((store: RootState) => store.userSlice.userDetail);
    const { name, mobileNumber, email, password, image, id } = action.payload;
    const formData = new FormData();

    formData.append('name', name);
    if (mobileNumber) {
      formData.append('number', `+971${mobileNumber}`);
    }
    formData.append('email', email);
    formData.append('password', password);
    formData.append('userType', 'AA');

    if (image instanceof File) {
      formData.append('image', image);
    }
    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateUser + id, formData);
    } else {
      result = yield call(request.post, endpoints.addAdmin, formData);
    }

    yield put(addAdminSuccess(result.data));
    if (id) {
      yield put(
        getUserDetailSuccess({
          ...data,
          ...result.data.data,
          userType: data.userType,
        })
      );
    }
  } catch (err: any) {
    yield put(addAdminFailed(err));
  }
}

// ----------------------------------------

function* updateUserStatusSaga(action: ReturnType<typeof updateUserStatus>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.userSlice.users);
    const result = yield call(request.patch, endpoints.updateUserStatus + id);
    yield put(updateUserStatusSuccess(result.data.message));
    const updatedData = data.map((item: any) => {
      if (item.id === id) {
        return {
          ...item,
          active: !item.active,
        };
      }
      return item;
    });
    yield put(getUsersSuccess(updatedData));
  } catch (err: any) {
    yield put(updateUserStatusFailed(err));
  }
}

// -------------------------------------------------

function* userSagas() {
  yield all([
    takeLatest([getUsers, addVendorSuccess, addUserAddressSuccess, addAdminSuccess], getUsersSaga),
    takeLatest(getAddress, getAddressSaga),
    takeLatest(addVendor, addVendorSaga),
    takeLatest(addAdmin, addAdminSaga),
    takeLatest(addMaster, addMasterSaga),
    takeLatest(getUserDetail, getUserDetailSaga),
    takeLatest(updateUserStatus, updateUserStatusSaga),
  ]);
}

export { userSagas };
