/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import { getCustomer, getCustomerFailed, getCustomerSuccess } from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

// ----------------------------------------
function* getCustomersSaga(action: ReturnType<typeof getCustomer>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getAllCustomers, {
      params: {
        limit,
        page: q ? 1 : page,
        q,
      },
    });

    yield put(getCustomerSuccess(result.data.data));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getCustomerFailed(err));
  }
}

// ----------------------------------------

function* customerSagas() {
  yield all([takeLatest([getCustomer], getCustomersSaga)]);
}

export { customerSagas };
