import * as Yup from 'yup';

import { Strings } from '@app/constants';

export const slotManagementSchema = Yup.object()
  .shape({
    slotType: Yup.string().required(
      Strings.validation.selectRequired(Strings.slotManagement.slotType)
    ),
    deliveryStartTime: Yup.string().required(
      Strings.validation.selectRequired(Strings.slotManagement.deliveryStartTime)
    ),
    deliveryEndTime: Yup.string().required(
      Strings.validation.selectRequired(Strings.slotManagement.deliveryEndTime)
    ),
    duration: Yup.string().test(
      'is-express',
      Strings.validation.fieldRequired(Strings.slotManagement.duration),
      function (value) {
        const slotType = this.parent.slotType;
        if (slotType === 'express') {
          return !!value;
        }
        return true;
      }
    ),
    deliveryCharge: Yup.string().required(
      Strings.validation.fieldRequired(Strings.slotManagement.deliveryCharge)
    ),
    capacity: Yup.string().required(
      Strings.validation.fieldRequired(Strings.slotManagement.maximumOrders)
    ),
    orderCutOff: Yup.string()
      .nullable()
      .test(
        'is-normal',
        Strings.validation.fieldRequired(Strings.slotManagement.orderCutOff),
        function (value) {
          const slotType = this.parent.slotType;
          if (slotType === 'normal') {
            return !!value;
          }
          return true;
        }
      ),
    city: Yup.string().required(Strings.validation.selectRequired(Strings.field.city)),
  })
  .required();
