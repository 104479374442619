import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { useTable } from '@app/components/table';
import SkuList from './sku-list';
import { useDialog, useRequestState } from '@app/hooks';
import AddSku from './add-sku';
import { selectSkuState } from '../selectors';
import { getSku } from '../slice';
import { usePageCount } from '@app/hooks/use-page-count';

const TABLE_HEAD_CSV = [
  { key: 'id', label: 'id', align: 'left' },
  { key: 'skuId', label: 'SKU Id', align: 'left' },
  { key: 'name', label: 'Name', align: 'left' },
  { key: 'productsName', label: 'Products', align: 'left', sortable: false },
  { key: 'parentCategory', label: 'Parent Category', align: 'left', sortable: false },
  { key: 'category', label: 'Category', align: 'left', sortable: false },
  { key: 'skuAttributes', label: 'Attributes', align: 'left', sortable: false },
  { key: 'desc', label: 'Description', align: 'left', sortable: false },
  { key: 'active', label: 'Status', align: 'left', sortable: false },
];
const SkuListPage = () => {
  const dispatch = useDispatch();
  const tableProps = useTable();
  const addSkuDialog = useDialog();
  const { page, rowsPerPage } = tableProps;

  const { count } = usePageCount(getSku);

  const { data, loading } = useRequestState({
    stateSelector: selectSkuState,
    errorShown: true,
  });

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (query.length !== 0) {
      dispatch(getSku({ page, q: query }));
    } else {
      dispatch(getSku({ page, limit: rowsPerPage }));
    }
  }, [dispatch, page, query, rowsPerPage]);

  const handleSearchChange = (event: any) => {
    setQuery(event.target.value);
  };

  const flattenAry = (data: any) => {
    const value = data?.map((elem: any) => {
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      return `${elem?.attribute?.name}:${elem?.attributeValue}`;
    });
    return value.join('; ');
  };

  const flattenData = (data: any) => {
    const serializeData = data?.map((elem: any) => {
      return {
        ...elem,
        skuAttributes: flattenAry(elem.skuAttributes),
        productsName: elem.products?.name || '',
        parentCategory: elem?.products?.category?.parentCategory?.name || '',
        category: elem.products?.category.name || '',
      };
    });
    return serializeData;
  };

  return (
    <>
      <Header
        title={Strings.pageTitle.sku}
        data={flattenData(data)}
        helperText={''}
        actionButtonTitle={Strings.button.addSku}
        onClickActionButton={addSkuDialog.show}
        searchBarProps={{
          placeholder: Strings.common.search,
          onChange: handleSearchChange,
        }}
        csvHead={TABLE_HEAD_CSV}
        csvFileName={'sku-list.csv'}
        orientation={'landscape'}
        additionalExportButtonTitle={Strings.button.exportCsv}
        additionalExportPdfButtonTitle={Strings.button.exportpdf}
      />

      <SkuList loading={loading} tableProps={tableProps} rows={data} count={count} page={page} />
      {addSkuDialog.visible && <AddSku open onClose={addSkuDialog.hide} />}
    </>
  );
};

export { SkuListPage };
