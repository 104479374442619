import {
  Stack,
  Button,
  Box,
  Typography,
  FormGroup,
  MenuItem,
  FormControlLabel,
  Switch,
  Checkbox,
} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { get } from 'lodash';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { yupResolver } from '@hookform/resolvers/yup';

import { FormProvider, RHFSelect, RHFTextField } from '@app/components';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { DetailsSlotManagement } from '@app/modules/slot-management-detail/types';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { addSlot } from '@app/modules/slot-management-detail/slice';
import { useRequestState } from '@app/hooks';
import { selectAddSlotState, selectAllSlotState } from '../selectors';
import { StyledAvailableBoxContent, StyledBoxContent, StyledMainBoxContent } from './styles';
import CheckboxLabel from './checkboxLabel';
import { RHFTimePicker } from '@app/components/hook-form/RHFTimePicker';
import { slotManagementSchema } from '../validators';

interface Props {
  row?: DetailsSlotManagement;
}

const SlotManagementDetailPage = ({ row }: Props) => {
  const navigate = useNavigate();
  const [days, setDays] = useState<string[]>(
    typeof row?.availableDays === 'string' ? row.availableDays.split(',') : []
  );
  const [checkedValue, setCheckedValue] = useState(true);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<DetailsSlotManagement | null>(null);

  const dispatch = useDispatch();

  const { state } = useLocation();

  const { data } = useRequestState({
    stateSelector: selectAllSlotState,
    errorShown: true,
  });

  const specificId = state?.id;
  const rows = data?.find((item: any) => item?.id === specificId);

  useEffect(() => {
    if (rows?.availableDays) {
      setDays((rows?.availableDays).split(','));
    }
  }, [rows?.availableDays]);

  const methods = useForm<DetailsSlotManagement>({
    resolver: yupResolver(slotManagementSchema) as any,
    defaultValues: {
      name: get(rows, 'name', ''),
      slotType: get(rows, 'slotType', ''),
      deliveryStartTime: rows
        ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
          dayjs(`2022-01-01 ${get(rows, 'deliveryStartTime', '00:00')}`)
        : null,
      // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      deliveryEndTime: rows ? dayjs(`2022-01-01 ${get(rows, 'deliveryEndTime', '00:00')}`) : null,
      orderCutOff: rows ? dayjs(`2022-01-01 ${get(rows, 'orderCutOff', '00:00')}`) : null,
      deliveryCharge: get(rows, 'deliveryCharge', ''),
      capacity: get(rows, 'capacity', ''),
      duration: get(rows, 'duration', ''),
      availableDays: get(rows, 'availableDays', '').split(',') ?? [],
      specificDate: get(rows, 'specificDate', null),
      city: get(rows, 'city', ''),
    },
  });

  useEffect(() => {
    if (rows) {
      setInitialValues(methods.getValues());
    }
  }, [rows, methods]);

  const handleDaysCheck = (elem: string, value: boolean) => {
    if (days.includes(elem)) {
      setDays((prevDays) => prevDays.filter((day) => day !== elem));
    } else {
      setDays((prevDays) => [...prevDays, elem]);
    }
  };

  const navigateToSlotList = useCallback(() => {
    navigate(PATH_DASHBOARD.slotManagement);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectAddSlotState,
    errorShown: true,
    successMessageShown: true,
    onSuccess: navigateToSlotList,
  });

  const onSubmit = (form: DetailsSlotManagement) => {
    let durationAsNumber = parseInt(form.duration);
    let orderCut = form.orderCutOff;

    if (form.slotType === 'normal') {
      durationAsNumber = 0;
    }
    if (form.slotType === 'express') {
      orderCut = null;
    }

    dispatch(
      addSlot({
        ...form,
        id: rows?.id,
        duration: durationAsNumber,
        orderCutOff: orderCut,
        availableDays: days,
        specificDate: checkedValue
          ? form.specificDate
            ? dayjs(form.specificDate).format('MM/DD/YYYY')
            : null
          : null,
      })
    );
  };

  const weekDays = [
    { label: 'mon', value: false },
    { label: 'tue', value: false },
    { label: 'wed', value: false },
    { label: 'thu', value: false },
    { label: 'fri', value: false },
    { label: 'sat', value: false },
    { label: 'sun', value: false },
  ];
  const slotTypeValue = useWatch({
    control: methods.control,
    name: 'slotType',
  });

  useEffect(() => {
    if (slotTypeValue === 'normal') {
      const startTime = methods.watch('deliveryStartTime');
      const endTime = methods.watch('deliveryEndTime');

      if ((startTime && dayjs(startTime).isValid()) ?? (endTime && dayjs(endTime).isValid())) {
        const formattedStartTime = startTime ? dayjs(startTime).format('hh:mm A') : '';
        const formattedEndTime = endTime ? dayjs(endTime).format('hh:mm A') : '';
        const nameValue = `${formattedStartTime} - ${formattedEndTime}`;
        methods.setValue('name', nameValue);
      } else {
        methods.setValue('name', '');
      }
    } else if (slotTypeValue === 'express') {
      const duration: string = methods.watch('duration');

      methods.setValue('name', duration === '' ? `Express` : `Express (${duration} mins)`);
    }
  }, [
    methods,
    slotTypeValue,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('deliveryStartTime'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('deliveryEndTime'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    methods.watch('duration'),
  ]);

  return (
    <>
      <Stack>
        <Header
          backButton
          title={state ? Strings.pageTitle.editSlotManagement : Strings.pageTitle.addSlotManagement}
          onClickBack={() => {
            navigateToSlotList();
          }}
        />
      </Stack>
      <FormProvider methods={methods}>
        <Box
          sx={{
            '& .MuiTextField-root': { m: 3, marginTop: 0, width: '25ch' },
          }}
        >
          <StyledAvailableBoxContent>
            <Typography variant="h4">{Strings.slotManagement.availableDays}</Typography>
            <StyledBoxContent />
            <FormGroup aria-label="position" row>
              {weekDays.map((elem: { label: string; value: boolean }, index: number) => (
                <CheckboxLabel
                  key={index}
                  label={elem.label}
                  active={methods.watch('availableDays')?.includes(elem.label) ?? false}
                  handleCheck={handleDaysCheck}
                />
              ))}
            </FormGroup>
          </StyledAvailableBoxContent>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
            }}
          >
            <RHFSelect name="slotType" label={Strings.field.slotType} variant="outlined" select>
              <MenuItem value="normal">{Strings.slotManagement.normal}</MenuItem>
              <MenuItem value="express">{Strings.slotManagement.express}</MenuItem>
            </RHFSelect>

            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RHFTimePicker
                name="deliveryStartTime"
                label={Strings.slotManagement.deliveryStartTime}
                sx={{ margin: '10px' }}
              />
            </Stack>

            <Stack sx={{ justifyContent: 'center', alignItems: 'center' }}>
              <RHFTimePicker
                name="deliveryEndTime"
                label={Strings.slotManagement.deliveryEndTime}
              />
            </Stack>

            {slotTypeValue === 'express' && (
              <RHFTextField
                name="duration"
                label={Strings.slotManagement.expressDuration}
                fullWidth
                variant="outlined"
              />
            )}
            {slotTypeValue === 'normal' && (
              <Stack>
                <RHFTimePicker name="orderCutOff" label={Strings.slotManagement.orderCutOff} />
              </Stack>
            )}
            <RHFTextField
              name="deliveryCharge"
              label={Strings.slotManagement.deliveryCharge}
              fullWidth
              variant="outlined"
            />
            <RHFSelect name="city" label={Strings.field.city} variant="outlined" select>
              <MenuItem value="dubai">{Strings.slotManagement.dubai}</MenuItem>
              <MenuItem value="abu dhabi">{Strings.slotManagement.abuDhabi}</MenuItem>
              <MenuItem value="sharjah">{Strings.slotManagement.sharjah}</MenuItem>
            </RHFSelect>
          </Box>

          <StyledMainBoxContent>
            <Typography variant="h4">{Strings.slotManagement.capacity}</Typography>
            <StyledBoxContent />
            <FormControlLabel
              control={<Switch defaultChecked />}
              label="Default"
              sx={{ marginLeft: 2 }}
            />
          </StyledMainBoxContent>

          <RHFTextField
            name="capacity"
            label={Strings.slotManagement.maximumOrders}
            fullWidth
            variant="outlined"
            helperText="Maximum orders"
          />
        </Box>

        <Box
          sx={{
            '& .MuiTextField-root': { m: 3, marginTop: 0, width: '25ch' },
          }}
        >
          <StyledMainBoxContent sx={{ alignItems: 'center' }}>
            <Checkbox
              checked={checkedValue}
              name="showOnSpecificDate"
              defaultChecked
              onChange={() => {
                setCheckedValue(!checkedValue);
              }}
            />
            <Typography sx={{ fontSize: '14px' }}>
              {Strings.slotManagement.showOnSpecificDatesOnly}
            </Typography>
          </StyledMainBoxContent>

          <Stack>
            <DatePicker
              label="Slot date"
              name="slotDate"
              disabled={!checkedValue}
              value={checkedValue ? (rows?.specificDate ? dayjs(rows?.specificDate) : null) : null}
              onChange={(newValue: any) => {
                methods.setValue('specificDate', newValue);
              }}
              format="MM-DD-YYYY"
            />
          </Stack>

          <RHFTextField
            name="name"
            fullWidth
            variant="outlined"
            label={Strings.field.name}
            disabled
          />
        </Box>

        <Box marginBottom={3}>
          <Button variant="outlined" style={{ marginRight: 5, marginLeft: 25 }}>
            {Strings.button.cancel}
          </Button>
          <LoadingButton
            loading={loading}
            variant="contained"
            type="submit"
            onClick={methods.handleSubmit(onSubmit)}
            // disabled={
            //   JSON.stringify(
            //     Object.fromEntries(
            //       Object.entries(methods.watch() ?? {}).map(([key, value]) => [
            //         key,
            //         value?.toString() ?? '',
            //       ])
            //     )
            //   ) ===
            //   JSON.stringify(
            //     Object.fromEntries(
            //       Object.entries(initialValues ?? {}).map(([key, value]) => [
            //         key,
            //         value?.toString() ?? '',
            //       ])
            //     )
            //   )
            // }
          >
            {Strings.button.save}
          </LoadingButton>
        </Box>
      </FormProvider>
    </>
  );
};

export { SlotManagementDetailPage };
