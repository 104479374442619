import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import {
  IAddAdminUserSchema,
  IAddMasterSchema,
  IAddUserSchema,
  IAddresses,
  IUser,
  IUserState,
} from './types';

const initialState: IUserState = {
  users: {},
  addVendor: {},
  addAdmin: {},
  ViewUser: {},
  addUserAddress: {},
  userDetail: {},
  addMaster: {},
  address: {},
  updateUserStatus: {},
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUsers(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.users.status = RequestStatus.RUNNING;
    },
    getUsersSuccess(state, action: PayloadAction<IUserState['users']['data']>) {
      state.users.status = RequestStatus.SUCCESS;
      state.users.data = action.payload;
    },
    getUsersFailed(state, action: PayloadAction<IRequestFailed>) {
      state.users.status = RequestStatus.ERROR;
      state.users.error = action.payload;
    },
    //
    getAddress(state, action: PayloadAction<{ q?: any }>) {
      state.address.status = RequestStatus.RUNNING;
    },
    getAddressSuccess(state, action: PayloadAction<IAddresses>) {
      state.address.status = RequestStatus.SUCCESS;
      state.address.data = action.payload;
    },
    getAddressFailed(state, action: PayloadAction<IRequestFailed>) {
      state.address.status = RequestStatus.ERROR;
      state.address.error = action.payload;
    },
    //
    getUserDetail(state, action: PayloadAction<{ id: number }>) {
      state.userDetail.status = RequestStatus.RUNNING;
    },
    getUserDetailSuccess(state, action: PayloadAction<IUserState['userDetail']['data']>) {
      state.userDetail.status = RequestStatus.SUCCESS;
      state.userDetail.data = action.payload;
    },
    getUserDetailFailed(state, action: PayloadAction<IRequestFailed>) {
      state.userDetail.status = RequestStatus.ERROR;
      state.userDetail.error = action.payload;
    },
    //

    addVendor(state, action: PayloadAction<IAddUserSchema>) {
      state.addVendor.status = RequestStatus.RUNNING;
    },
    addVendorSuccess(state, action: PayloadAction<IUser>) {
      state.addVendor.status = RequestStatus.SUCCESS;
      state.addVendor.data = action.payload;
    },
    addUserAddressSuccess(state, action: PayloadAction<IUser>) {
      state.addUserAddress.status = RequestStatus.SUCCESS;
      state.addUserAddress.data = action.payload;
    },
    addVendorFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addVendor.status = RequestStatus.ERROR;
      state.addVendor.error = { message: action.payload.message };
    },
    //
    addAdmin(state, action: PayloadAction<IAddAdminUserSchema>) {
      state.addAdmin.status = RequestStatus.RUNNING;
    },
    addAdminSuccess(state, action: PayloadAction<IUser>) {
      state.addAdmin.status = RequestStatus.SUCCESS;
      state.addAdmin.data = action.payload;
    },
    addAdminFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addAdmin.status = RequestStatus.ERROR;
      state.addAdmin.error = action.payload;
    },
    addMaster(state, action: PayloadAction<IAddMasterSchema>) {
      state.addMaster.status = RequestStatus.RUNNING;
    },
    addMasterSuccess(state, action: PayloadAction<IUser>) {
      state.addMaster.status = RequestStatus.SUCCESS;
      state.addMaster.data = action.payload;
    },
    addMasterFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addMaster.status = RequestStatus.ERROR;
      state.addMaster.error = action.payload;
    },
    //
    updateUserStatus(state, action: PayloadAction<{ id: number }>) {
      state.updateUserStatus.status = RequestStatus.RUNNING;
    },
    updateUserStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updateUserStatus.status = RequestStatus.SUCCESS;
      state.updateUserStatus.data = action.payload;
    },
    updateUserStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updateUserStatus.status = RequestStatus.ERROR;
      state.updateUserStatus.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  getUsers,
  getUsersSuccess,
  getUsersFailed,
  addVendor,
  addVendorSuccess,
  addVendorFailed,
  addUserAddressSuccess,
  addAdmin,
  addAdminSuccess,
  addAdminFailed,
  addMaster,
  addMasterFailed,
  addMasterSuccess,
  getUserDetail,
  getUserDetailFailed,
  getUserDetailSuccess,
  getAddress,
  getAddressSuccess,
  getAddressFailed,
  updateUserStatus,
  updateUserStatusSuccess,
  updateUserStatusFailed,
} = slice.actions;
