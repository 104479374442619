import { Divider, Stack, DialogContent, DialogActions, Dialog, Button } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { debounce, get } from 'lodash';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFTextField, RHFAutocomplete } from '@app/components';
import { IAddInventorySchema, IAddSku, IInventory, IAddUser } from '../types';
import { addInventorySchema } from '../validators';
import { selectAddInventoryState } from '../selectors';
import { addInventory } from '../slice';
import { ISku, getSku, selectSkuState } from '@app/modules/sku';
import { useTable } from '@app/components/table';
import { getUsers, selectUsersState } from '@app/modules/users';
import { IUser } from '@app/modules/users/types';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: IInventory;
}

const AddInventory = ({ open, onClose, row }: Props) => {
  const dispatch = useDispatch();
  const [skuSearchingData, setSkuSearchingData] = useState('');
  const [usersSearchingData, setUsersSearchingData] = useState('');

  const methods = useForm<IAddInventorySchema>({
    resolver: yupResolver(addInventorySchema) as any,
    defaultValues: {
      stock: get(row, 'stock', ''),
      price: get(row, 'price', ''),
      ourPrice: get(row, 'ourPrice', ''),
      vendorId: get(row, 'vendorId', ''),
      skuId: get(row, 'skuId', ''),
      discountPrice: get(row, 'discountPrice', ''),
    },
  });

  useEffect(() => {
    methods.reset({
      stock: get(row, 'stock', ''),
      price: get(row, 'price', ''),
      ourPrice: get(row, 'ourPrice', ''),
      vendorId: get(row, 'vendorId', ''),
      skuId: get(row, 'skuId', ''),
      discountPrice: get(row, 'discountPrice', ''),
    });
  }, [row, methods]);

  const tableProps = useTable();
  const page = tableProps.page;
  const [skuPage, setSkuPage] = useState(2);
  const [userPage, setUserPage] = useState(2);

  const handleSkuInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setSkuSearchingData(value);
  }, 300);

  const handleUserInputChange = debounce((event: React.ChangeEvent<any>, value) => {
    setUsersSearchingData(value);
  }, 300);

  useEffect(() => {
    if (usersSearchingData.length !== 0) {
      dispatch(getUsers({ page, q: usersSearchingData }));
    } else {
      dispatch(getUsers({ page }));
    }
  }, [dispatch, page, usersSearchingData]);

  useEffect(() => {
    if (skuSearchingData.length !== 0) {
      dispatch(getSku({ page, q: skuSearchingData }));
    } else {
      dispatch(getSku({ page }));
    }
  }, [dispatch, page, skuSearchingData]);

  const onSubmit = (form: IAddInventorySchema) => {
    const id = row?.id ?? 0;
    const sku: any = methods.getValues('skuId');
    const vendor: any = methods.getValues('vendorId');
    const discountPrice = parseFloat(form.discountPrice);
    dispatch(
      addInventory({
        ...form,
        id,
        skuId: sku.id,
        vendorId: vendor.id,
        discountPrice,
      })
    );
  };

  const { loading: saveInventory } = useRequestState({
    stateSelector: selectAddInventoryState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const { data: skusData, loading: skuStateLoading } = useRequestState({
    stateSelector: selectSkuState,
  });

  const { data: usersData, loading: userStateLoading } = useRequestState({
    stateSelector: selectUsersState,
  });

  const [skus, setSkus] = useState<ISku[]>([]);
  const [users, setUsers] = useState<IUser[]>([]);

  useEffect(() => {
    if (skusData) {
      if (!skuSearchingData) {
        setSkus((prevSkus) => [
          ...prevSkus,
          ...skusData.filter((skuData) => !prevSkus.some((prevsku) => prevsku.id === skuData.id)),
        ]);
      } else {
        setSkus(skusData);
      }
    }
  }, [skuSearchingData, skusData]);

  useEffect(() => {
    if (usersData) {
      if (!usersSearchingData) {
        setUsers((prevUsers) => [
          ...prevUsers,
          ...usersData.filter(
            (userData) => !prevUsers.some((prevUser) => prevUser.id === userData.id)
          ),
        ]);
      } else {
        setUsers(usersData);
      }
    }
  }, [usersSearchingData, usersData]);

  const handleCallUserApi = async () => {
    const nextPage = userPage + 1;
    setUserPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getUsers({ page: userPage, limit: 100 }));
      resolve();
    });
  };

  const handleCallSkuApi = async () => {
    const nextPage = skuPage + 1;
    setSkuPage(nextPage);
    await new Promise<void>((resolve) => {
      dispatch(getSku({ page: skuPage, limit: 100 }));
      resolve();
    });
  };

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.pageTitle.editInventory : Strings.button.addInventory}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="stock"
                required
                label={Strings.field.stock}
                fullWidth
                variant="outlined"
              />

              <RHFTextField
                name="price"
                required
                label={Strings.field.price}
                fullWidth
                variant="outlined"
              />

              <RHFTextField
                name="ourPrice"
                required
                label={Strings.field.ourPrice}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name="discountPrice"
                label={Strings.field.discountPrice}
                fullWidth
                variant="outlined"
                required
              />

              <RHFAutocomplete
                name="vendorId"
                label={Strings.field.vendor}
                options={((users as IAddUser[]) || [])
                  .filter((usr) => usr.userType === 'VU')
                  .map((user) => ({
                    label: user.firstName,
                    id: user.id,
                  }))}
                onInputChange={handleUserInputChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                callApi={handleCallUserApi}
                hasMore={usersData?.length !== 0 && !usersSearchingData}
                loading={userStateLoading}
              />

              <RHFAutocomplete
                name="skuId"
                label={Strings.field.sku}
                options={((skus as IAddSku[]) || []).map((sku) => ({
                  label: sku.name,
                  id: sku.id,
                }))}
                onInputChange={handleSkuInputChange}
                getOptionKey={(option: any) => option.id}
                isOptionEqualToValue={(option: IAddSku, value: IAddSku) => option.id === value.id}
                callApi={handleCallSkuApi}
                hasMore={skusData?.length !== 0 && !skuSearchingData}
                loading={skuStateLoading}
              />
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={saveInventory}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddInventory;
