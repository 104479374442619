import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IDashboard } from '../types';
import { DashboardRow } from './dashboard-row';

const TABLE_HEAD = [
  { id: 'id', label: 'ID', align: 'left' },
  { id: 'orderId', label: 'Order ID', align: 'center' },
  { id: 'customerName', label: 'Customer Name', align: 'center' },
  { id: 'customerNumber', label: 'Customer Number', align: 'center' },
  { id: 'customerID', label: 'Customer Id', align: 'center' },
  { id: 'orderStatus', label: 'Order Status', align: 'center' },
  { id: 'userPreference', label: 'User Preference', align: 'center' },
  { id: 'address', label: 'Address', align: 'center' },
  { id: 'skuId', label: 'SKU Id', align: 'center' },
  { id: 'skuName', label: 'SKU Name', align: 'center' },
  { id: 'vendorName', label: 'Vendor Name', align: 'center' },
  { id: 'paymentMode', label: 'Payment Mode', align: 'center' },
  { id: 'otyOrdered', label: 'Qty Ordered', align: 'center' },
  { id: 'ourPrice', label: 'Our Price', align: 'center' },
  { id: 'discount', label: 'Discount', align: 'center' },
  { id: 'discountAmount', label: 'Discount Amount', align: 'center' },
  { id: 'discountedPrice', label: 'Discounted Price', align: 'center', sortable: false },
  { id: 'couponDiscount', label: 'Coupon Discount', align: 'center', sortable: false },
  { id: 'finalPrice', label: 'Final Price', align: 'center', sortable: false },
  { id: 'orderDate', label: 'Order Date', align: 'center', sortable: false },
  { id: 'deliveryDate', label: 'Delivery Date', align: 'center' },
  { id: 'deliveryCharge', label: 'Delivery Charge', align: 'center' },
  { id: 'deliverySlot', label: 'Delivery Slot', align: 'center' },
];

interface Props {
  tableProps: TableProps;
  rows?: IDashboard[];
  loading: boolean;
  count: number;
}

const DashboardList = ({ tableProps, rows, loading, count }: Props) => {
  return (
    <PaginatedTable<IDashboard>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <DashboardRow
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={() => {
            ('');
          }}
        />
      )}
    </PaginatedTable>
  );
};
export default DashboardList;
