import { useEffect } from 'react';

import { PaginatedTable } from '@app/components/paginated-table';
import { TableProps } from '@app/components/table';
import { IAttribute } from '../types';
import { Row } from './attribute-row';

const TABLE_HEAD = [
  { id: 'id', label: 'Id', align: 'left', sortable: false },
  { id: 'name', label: 'Name', align: 'left', sortable: false },
  { id: 'action', label: 'Action', align: 'left', sortable: false },
];

interface Props {
  tableProps: TableProps;
  rows?: IAttribute[];
  loading: boolean;
  count?: number;
  selectedAttributes: any;
}

const SkuList = ({ tableProps, rows, loading, count, selectedAttributes }: Props) => {
  useEffect(() => {
    if (selectedAttributes?.length > 0) {
      const idsToSelect = selectedAttributes.map((attr: any) => Number(attr.id));
      tableProps.onSelectRow(idsToSelect);
    }
  }, [selectedAttributes]);

  return (
    <PaginatedTable<IAttribute>
      head={TABLE_HEAD}
      tableProps={tableProps}
      data={rows}
      loading={loading}
      isServerSidePaging={true}
      totalCount={count}
    >
      {(row) => (
        <Row
          row={row}
          selected={tableProps.selected.includes(row.id)}
          onSelectRow={tableProps.onSelectRow}
        />
      )}
    </PaginatedTable>
  );
};
export default SkuList;
