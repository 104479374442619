import * as Yup from 'yup';

import { Strings } from '@app/constants';

export const addPromotionSchema = Yup.object()
  .shape({
    code: Yup.string().required(Strings.validation.fieldRequired(Strings.field.code)),
    minimumOrderValue: Yup.number()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Minimum order value must be a number')
      .positive('Minimum order value must be greater than zero')
      .nullable()
      .test(
        'required-if-amount',
        Strings.validation.fieldRequired(Strings.promotion.mov),
        function (value) {
          const { discountType } = this.parent;
          if (discountType && discountType.trim().toLowerCase() === 'amount') {
            return value !== null && value !== undefined;
          }
          return true;
        }
      ),
    totalUsageLimit: Yup.number()
      .nullable()
      .positive('Total Usage  must be greater than zero')
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Total Usage must be a number')
      .required(Strings.validation.fieldRequired(Strings.field.totalUsageLimit)),
    usagePerUserLimit: Yup.number()
      .nullable()
      .positive('Usage/Person value must be greater than zero')
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Usage/Person value must be a number')
      .required(Strings.validation.fieldRequired(Strings.field.usagePerUserLimit))
      .test(
        'is-less-than-total',
        'Usage/Person value must not exceed Total Usage Limit',
        function (value) {
          const { totalUsageLimit } = this.parent;
          return value == null || totalUsageLimit == null || value <= totalUsageLimit;
        }
      ),
    name: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.name))
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).+$/,
        'Code must contain at least one letter and one number'
      ),
    voucherType: Yup.string().required(Strings.validation.fieldRequired(Strings.field.voucherType)),
    discountType: Yup.string().required(
      Strings.validation.fieldRequired(Strings.field.discountType)
    ),
    description: Yup.string().required(Strings.validation.fieldRequired(Strings.field.description)),
    category: Yup.string().required(Strings.validation.selectRequired(Strings.field.category)),
    country: Yup.string().required(Strings.validation.selectRequired(Strings.field.country)),
    image: Yup.mixed().required(Strings.validation.uploadRequired(Strings.field.image)),
    maxDiscountAmount: Yup.number()
      .nullable()
      .positive('Maximum Amount must be greater than zero')
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .typeError('Maximum Amount must be a number')
      .test(
        'is-percentage',
        Strings.validation.fieldRequired(Strings.promotion.maximumAmount),
        function (value) {
          const discountType = this.parent.discountType;
          if (discountType.trim().toLowerCase() === 'percentage') {
            return !!value;
          }
          return true;
        }
      ),
    startDate: Yup.string()
      .nullable()
      .required(Strings.validation.selectRequired(Strings.promotion.startDate)),
    endDate: Yup.string()
      .nullable()
      .required(Strings.validation.selectRequired(Strings.promotion.endDate)),
    discountAmount: Yup.number()
      .nullable()
      .transform((value, originalValue) => (originalValue === '' ? null : value))
      .positive('Discount Amount value must be greater than zero')
      .typeError('Total Usage must be a number')
      .required(Strings.validation.fieldRequired(Strings.promotion.discountAmount))
      .test(
        'max-100-if-percentage',
        'Discount amount cannot be greater than 100',
        function (value) {
          const { discountType } = this.parent;
          if (discountType === 'Percentage' && value > 100) {
            return this.createError({
              message: 'Discount Amount cannot be greater than 100',
            });
          }
          return true;
        }
      ),
    categorySelected: Yup.string().when('category', {
      is: 'Specific customer',
      then: (schema: any) =>
        schema.required(Strings.validation.fieldRequired(Strings.promotion.specificCustomer)),
      otherwise: (schema: any) => schema.notRequired(),
    }),
  })
  .required();
