import { Card, CardMedia, Stack, Switch, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { useRequestState } from '@app/hooks';
import { Strings } from '@app/constants';
import { ICustomerPopup } from '../types';
import { RowActions } from './row-actions';
import { updateCustomerPopupStatus } from '../slice';
import { selectUpdateCustomerPopupStatus } from '../selectors';

interface Props {
  cardDetails: ICustomerPopup;
}

export const CustomerPopupCard = (Props: Props) => {
  const { active, header, id, image, statement1, statement2, statement3, oneTimeView } =
    Props.cardDetails;

  const dispatch = useDispatch();

  const handleActivePopup = (id: number) => {
    dispatch(updateCustomerPopupStatus({ id }));
  };

  useRequestState({
    stateSelector: selectUpdateCustomerPopupStatus,
    successMessageShown: true,
  });

  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        p: 2,
        boxShadow: 2,
        borderRadius: 2,
        my: 1,
      }}
    >
      <Stack
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CardMedia
          component="img"
          alt="Popup Image"
          image={image}
          sx={{
            width: 170,
            height: 170,
            borderRadius: 2,
            objectFit: 'contain',
            mr: 2,
            justifyContent: 'center',
          }}
        />
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        <Stack flex={1} spacing={1} alignSelf={'center'}>
          <Typography variant="h6" fontWeight={700}>
            {header}
          </Typography>
          <Typography variant="body2">{statement1}</Typography>
          <Typography variant="body2">{statement2}</Typography>
          <Typography variant="body2">{statement3}</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={0}>
            <Typography variant="body2">{'Active'}</Typography>
            <Switch
              checked={active}
              onChange={() => {
                handleActivePopup(id);
              }}
            />
            <Typography variant="body2">{Strings.field.oneTimeView}</Typography>
            <Switch checked={!!oneTimeView} disabled />
          </Stack>
        </Stack>
        <Stack alignSelf="flex-start">
          <RowActions row={Props.cardDetails} />
        </Stack>
      </Stack>
    </Card>
  );
};
