import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { IPromotionState } from './types';

const initialState: IPromotionState = {
  promotion: {},
  addPromotion: {},
  promotionDetails: {},
  updatePromotionStatus: {},
};
const slice = createSlice({
  name: 'promotion',
  initialState,
  reducers: {
    getAllPromotion(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.promotion.status = RequestStatus.RUNNING;
    },
    getAllPromotionSuccess(state, action: PayloadAction<IPromotionState['promotion']['data']>) {
      state.promotion.status = RequestStatus.SUCCESS;
      state.promotion.data = action.payload;
    },
    getAllPromotionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.promotion.status = RequestStatus.ERROR;
      state.promotion.error = action.payload;
    },
    //
    addPromotion(state, action: PayloadAction<any>) {
      state.addPromotion.status = RequestStatus.RUNNING;
    },
    addPromotionSuccess(state, action: PayloadAction<any>) {
      state.addPromotion.status = RequestStatus.SUCCESS;
      state.addPromotion.data = action.payload;
    },
    addPromotionFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addPromotion.status = RequestStatus.ERROR;
      state.addPromotion.error = action.payload;
    },
    //
    getPromotionDetails(state, action: PayloadAction<{ id: number }>) {
      state.promotionDetails.status = RequestStatus.RUNNING;
    },
    getPromotionDetailsSuccess(state, action: PayloadAction<any>) {
      state.promotionDetails.status = RequestStatus.SUCCESS;
      state.promotionDetails.data = action.payload;
    },
    getPromotionDetailsFailed(state, action: PayloadAction<IRequestFailed>) {
      state.promotionDetails.status = RequestStatus.ERROR;
      state.promotionDetails.error = action.payload;
    },
    //
    updatePromotionStatus(state, action: PayloadAction<{ id: number | undefined }>) {
      state.updatePromotionStatus.status = RequestStatus.RUNNING;
    },
    updatePromotionStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.updatePromotionStatus.status = RequestStatus.SUCCESS;
      state.updatePromotionStatus.data = action.payload;
    },
    updatePromotionStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.updatePromotionStatus.status = RequestStatus.ERROR;
      state.updatePromotionStatus.error = action.payload;
    },
    //
  },
});
export default slice.reducer;

export const {
  getAllPromotion,
  getAllPromotionSuccess,
  getAllPromotionFailed,
  addPromotion,
  addPromotionSuccess,
  addPromotionFailed,
  getPromotionDetails,
  getPromotionDetailsFailed,
  getPromotionDetailsSuccess,
  updatePromotionStatus,
  updatePromotionStatusSuccess,
  updatePromotionStatusFailed,
} = slice.actions;
