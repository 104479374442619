/*
 * Root Saga
 */

import { bootstrapSagas } from '@app/modules/bootstrap';
import { authSagas } from '@app/modules/auth';
import { categorySagas } from '@app/modules/categories';
import { bannerSagas } from '@app/modules/banner';
import { productSagas } from '@app/modules/products';
import { attributeSagas } from '@app/modules/attribute';
import { skuSagas } from '@app/modules/sku';
import { userSagas } from '@app/modules/users';
import { slotSagas } from '@app/modules/slot-management-detail';
import { inventorySagas } from '@app/modules/inventory-banner-management';
import { collectionSagas } from '@app/modules/collection';
import { skuDetailsSagas } from '@app/modules/banner-detail';
import { collectionDetailSagas } from '@app/modules/collection-detail';
import { customerSagas } from '@app/modules/customers';
import { OrderSagas } from '@app/modules/order-management/saga';
import { orderDetailSagas } from '@app/modules/order-detail';
import { inventoryDetailSagas } from '@app/modules/inventory-detail';
import { aboutUsSagas } from '@app/modules/about-us';
import { promotionSagas } from '@app/modules/promotion';
import { customerPopupsSagas } from '@app/modules/customer-popup';

const rootSaga = [
  bootstrapSagas,
  authSagas,
  categorySagas,
  bannerSagas,
  productSagas,
  attributeSagas,
  skuSagas,
  userSagas,
  inventorySagas,
  collectionSagas,
  skuDetailsSagas,
  collectionDetailSagas,
  customerSagas,
  slotSagas,
  OrderSagas,
  orderDetailSagas,
  inventoryDetailSagas,
  aboutUsSagas,
  promotionSagas,
  customerPopupsSagas,
];

export { rootSaga };
