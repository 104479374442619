/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import { getOrder, getOrderFailed, getOrderSuccess } from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

function* getOrderSaga(action: ReturnType<typeof getOrder>): any {
  const { page, q, startDate, endDate, status, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getOrder, {
      params: {
        page: q ? 1 : page,
        limit,
        q,
        startDate,
        endDate,
        status,
      },
    });
    yield put(getOrderSuccess(result.data.order));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getOrderFailed(err));
  }
}
function* OrderSagas() {
  yield all([takeLatest([getOrder], getOrderSaga)]);
}

export { OrderSagas };
