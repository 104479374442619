import { useCallback } from 'react';
import {
  Divider,
  Stack,
  DialogContent,
  DialogActions,
  Dialog,
  Button,
  MenuItem,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { LoadingButton } from '@mui/lab';
import { get } from 'lodash';

import { Strings } from '@app/constants';
import { Header } from '@app/sections/header';
import { useRequestState } from '@app/hooks';
import { FormProvider, RHFSelect, RHFTextField, RHFUploadAvatar } from '@app/components';
import { IMAGE_BASE_URL } from '@app/configs';
import { addCustomerPopupsSchema } from '../validators';
import { IAddCustomerPopupSchema, ICustomerPopup } from '../types';
import { addCustomerPopup } from '../slice';
import { selectAddCustomerPopupState } from '../selectors';

interface Props {
  open: boolean;
  onClose: VoidFunction;
  row?: ICustomerPopup;
  selectedTargetAudience?: string;
}

const AddCustomerPopup = ({ open, onClose, row, selectedTargetAudience }: Props) => {
  const dispatch = useDispatch();

  const methods = useForm<IAddCustomerPopupSchema>({
    resolver: yupResolver(addCustomerPopupsSchema) as any,
    defaultValues: {
      targetAudience: get(row, 'targetAudience', ''),
      header: get(row, 'header', ''),
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      image: row?.image ? IMAGE_BASE_URL + row?.image : '',
      statement1: get(row, 'statement1', ''),
      statement2: get(row, 'statement2', ''),
      statement3: get(row, 'statement3', ''),
      oneTimeView: get(row, 'oneTimeView', ''),
    },
  });

  const onSubmit = (form: IAddCustomerPopupSchema) => {
    const id = row?.id ?? 0;
    dispatch(
      addCustomerPopup({
        ...form,
        id,
        image: methods.getValues('image'),
        selectedTargetAudience: row?.targetAudience
          ? row?.targetAudience
          : selectedTargetAudience ?? '',
        oneTimeView: form.oneTimeView,
      })
    );
  };

  const { loading } = useRequestState({
    stateSelector: selectAddCustomerPopupState,
    onSuccess: onClose,
    successMessageShown: true,
    errorShown: true,
  });

  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue('image', newFile, { shouldValidate: true });
      }
    },
    [methods]
  );

  return (
    <>
      <FormProvider methods={methods}>
        <Dialog open={open} fullWidth>
          <Header
            variant="dialog"
            title={row?.id ? Strings.button.editCustomerPopup : Strings.button.addCustomerPopup}
            onClose={onClose}
          />

          <Divider />

          <DialogContent>
            <Stack spacing={2} my={2}>
              <RHFTextField
                name="targetAudience"
                required
                label={Strings.field.targetAudience}
                variant="outlined"
                select
                disabled={!!row?.targetAudience}
              >
                <MenuItem value={'New customers'}>{Strings.customerPopup.newCustomers}</MenuItem>
                <MenuItem value={'Existing customers'}>
                  {Strings.customerPopup.existingCustomers}
                </MenuItem>
              </RHFTextField>
              <RHFSelect
                name="oneTimeView"
                required
                label={Strings.field.oneTimeView}
                variant="outlined"
                select
                sx={{ flex: 1 }}
              >
                <MenuItem value={'true'}>{Strings.customerPopup.true}</MenuItem>
                <MenuItem value={'false'}>{Strings.customerPopup.false}</MenuItem>
              </RHFSelect>
              <RHFTextField
                name="header"
                required
                label={Strings.field.header}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name={'statement1'}
                required
                label={Strings.field.statement1}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name={'statement2'}
                label={Strings.field.statement2}
                fullWidth
                variant="outlined"
              />
              <RHFTextField
                name={'statement3'}
                label={Strings.field.statement3}
                fullWidth
                variant="outlined"
              />
              <Stack alignItems={'start'}>
                <RHFUploadAvatar
                  name={'image'}
                  onDrop={handleDrop}
                  sx={{
                    borderRadius: '10px',
                    width: 115,
                    height: 115,
                    border: 'none',
                  }}
                  placeholderSx={{
                    borderRadius: '10px',
                    width: '100%',
                    height: '100%',
                  }}
                />
              </Stack>
            </Stack>
          </DialogContent>

          <Divider />

          <DialogActions>
            <Button variant="outlined" color="inherit" onClick={onClose}>
              {Strings.button.cancel}
            </Button>

            <LoadingButton
              loading={loading}
              type="submit"
              variant="contained"
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </DialogActions>
        </Dialog>
      </FormProvider>
    </>
  );
};

export default AddCustomerPopup;
