import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RequestStatus } from '@app/constants';
import { IRequestFailed, IRequestStatus } from '@app/types';
import { DetailsSlotManagement, ISlotState, AddSlotManagement } from './types';

const initialState: ISlotState = {
  slots: {},
  addSlot: {},
  slotStatus: {},
};
const slice = createSlice({
  name: 'addSlot',
  initialState,
  reducers: {
    getAllSlot(state, action: PayloadAction<{ page: number; q?: any; limit?: number }>) {
      state.slots.status = RequestStatus.RUNNING;
    },
    getAllSlotSuccess(state, action: PayloadAction<ISlotState['slots']['data']>) {
      state.slots.status = RequestStatus.SUCCESS;
      state.slots.data = action.payload;
    },
    getAllSlotFailed(state, action: PayloadAction<IRequestFailed>) {
      state.slots.status = RequestStatus.ERROR;
      state.slots.error = action.payload;
    },
    //

    addSlot(state, action: PayloadAction<DetailsSlotManagement>) {
      state.addSlot.status = RequestStatus.RUNNING;
    },
    addSlotSuccess(state, action: PayloadAction<AddSlotManagement>) {
      state.addSlot.status = RequestStatus.SUCCESS;
      state.addSlot.data = action.payload;
    },
    addSlotFailed(state, action: PayloadAction<IRequestFailed>) {
      state.addSlot.status = RequestStatus.ERROR;
      state.addSlot.error = action.payload;
    },
    //
    updateSlotStatus(state, action: PayloadAction<{ id: number }>) {
      state.slotStatus.status = RequestStatus.RUNNING;
    },
    updateSlotStatusSuccess(state, action: PayloadAction<IRequestStatus>) {
      state.slotStatus.status = RequestStatus.SUCCESS;
      state.slotStatus.data = action.payload;
    },
    updateSlotStatusFailed(state, action: PayloadAction<IRequestFailed>) {
      state.slotStatus.status = RequestStatus.ERROR;
      state.slotStatus.error = action.payload;
    },
  },
});
export default slice.reducer;

export const {
  addSlot,
  addSlotSuccess,
  addSlotFailed,
  getAllSlot,
  getAllSlotSuccess,
  getAllSlotFailed,
  updateSlotStatusSuccess,
  updateSlotStatusFailed,
  updateSlotStatus,
} = slice.actions;
