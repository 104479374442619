/* eslint-disable @typescript-eslint/restrict-plus-operands */
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  addProduct,
  addProductFailed,
  addProductSuccess,
  getProductDetail,
  getProductDetailFailed,
  getProductDetailSuccess,
  getProducts,
  getProductsFailed,
  getProductsSuccess,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

// ----------------------------------------
function* getProductsSaga(action: ReturnType<typeof getProducts>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getProducts, {
      params: {
        page: q ? 1 : page,
        q,
        limit,
      },
    });
    yield put(setPageCount({ id: action.type, count: result.data.total }));
    yield put(getProductsSuccess(result.data.product));
  } catch (err: any) {
    yield put(getProductsFailed(err));
  }
}
// ----------------------------------------
function* getProductDetailSaga(action: ReturnType<typeof getProductDetail>): any {
  const { id } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getProductDetail + id);
    yield put(getProductDetailSuccess(result.data.data[0]));
  } catch (err: any) {
    yield put(getProductDetailFailed(err));
  }
}

// ----------------------------------------

function* addProductSaga(action: ReturnType<typeof addProduct>): any {
  try {
    const { id, name, categoryId, categoryName, desc, image, petType } = action.payload;
    const { data } = yield select((store: RootState) => store.productSlice.productDetail);

    const formData = new FormData();

    formData.append('name', name);
    formData.append('categoryId', categoryId);
    formData.append('description', desc);
    formData.append('pet_type', petType?.toLowerCase());

    if (typeof image === 'object') {
      formData.append('images', image);
    }

    let result;
    if (id) {
      result = yield call(request.put, endpoints.updateProduct + id, formData);
    } else {
      result = yield call(request.post, endpoints.addProduct, formData);
    }
    yield put(addProductSuccess(result.data));
    if (id) {
      yield put(
        getProductDetailSuccess({
          ...data,
          ...result.data.product,
          category: { ...data.category, name: categoryName },
        })
      );
    }
  } catch (err: any) {
    yield put(addProductFailed(err));
  }
}
// ----------------------------------------

function* productSagas() {
  yield all([
    takeLatest([getProducts, addProductSuccess], getProductsSaga),
    takeLatest(addProduct, addProductSaga),
    takeLatest(getProductDetail, getProductDetailSaga),
  ]);
}

export { productSagas };
