import { useCallback, useState } from 'react';
import { IconButton } from '@mui/material';
import { useDispatch } from 'react-redux';

import { DeleteDialog } from '@app/components/dialog';
import {
  Iconify,
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from '@app/components';
import { Strings } from '@app/constants';
import { useDialog, useRequestState } from '@app/hooks';
import { ICustomerPopup } from '../types';
import { deleteCustomerPopup } from '../slice';
import { selectDeleteCustomerPopup } from '../selectors';
import AddCustomerPopup from './add-customer-popup';
import PopupModal from './customer-popup-preview';

interface Props {
  row: ICustomerPopup;
}

const RowActions = ({ row }: Props) => {
  const menuPopover = useMenuPopover();
  const deleteDialog = useDialog();
  const editDialog = useDialog();
  const dispatch = useDispatch();

  const [isPreviewOpen, setIsPreviewOpen] = useState<boolean>(false);

  const handleDeleteDialog = useCallback(() => {
    deleteDialog.hide();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleIsPreviewPopup = useCallback(() => {
    setIsPreviewOpen(true);
  }, []);

  useRequestState({
    stateSelector: selectDeleteCustomerPopup,
    successMessageShown: true,
    onSuccess: handleDeleteDialog,
  });

  const actions = [
    {
      label: Strings.rowActions.edit,
      callback: editDialog.show,
    },
    {
      label: Strings.rowActions.delete,
      callback: deleteDialog.show,
    },
    {
      label: Strings.rowActions.preview,
      callback: handleIsPreviewPopup,
    },
  ];

  return (
    <>
      <IconButton onClick={menuPopover.show}>
        <Iconify icon="eva:more-vertical-fill" />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>

      <DeleteDialog
        title={`${Strings.rowActions.delete} ${Strings.header.customerPopup}?`}
        caption={Strings.common.deleteCaption(Strings.header.customerPopup)}
        deleteButtonTitle={Strings.button.confirm}
        loading={false}
        onClose={deleteDialog.hide}
        visible={deleteDialog.visible}
        onClickDelete={() => {
          dispatch(deleteCustomerPopup({ id: row?.id }));
        }}
      />
      {editDialog.visible && <AddCustomerPopup open onClose={editDialog.hide} row={row} />}
      <PopupModal
        isOpen={isPreviewOpen}
        onClose={() => {
          setIsPreviewOpen(false);
        }}
        data={row}
      />
    </>
  );
};

export { RowActions };
