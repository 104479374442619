import { all, call, takeLatest, put } from 'redux-saga/effects';

import { request } from '@app/services';
import {
  getAllPromotion,
  getAllPromotionSuccess,
  getAllPromotionFailed,
  addPromotionSuccess,
  addPromotionFailed,
  addPromotion,
  getPromotionDetails,
  getPromotionDetailsSuccess,
  getPromotionDetailsFailed,
  updatePromotionStatus,
  updatePromotionStatusSuccess,
  updatePromotionStatusFailed,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';

function* getAllPromotionSaga(action: ReturnType<typeof getAllPromotion>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getAllPromotion, {
      params: {
        page: q ? 1 : page,
        q,
        limit,
      },
    });
    yield put(getAllPromotionSuccess(result.data.data?.vouchers));
    yield put(setPageCount({ id: action.type, count: result.data.data.totalCount }));
  } catch (err: any) {
    yield put(getAllPromotionFailed(err));
  }
}
//
function* addPromotionSagas(action: ReturnType<typeof addPromotion>): any {
  try {
    let result;
    const {
      id,
      code,
      name,
      voucherType,
      discountType,
      discountAmount,
      minimumOrderValue,
      totalUsageLimit,
      maxDiscountAmount,
      category,
      currency,
      country,
      usagePerUserLimit,
      startDate,
      endDate,
      status,
      image,
      description,
      selCustomer,
    } = action.payload;

    const formData = new FormData();

    formData.append('name', name);
    formData.append('code', code);
    formData.append('description', description);
    formData.append('voucherType', voucherType);
    formData.append('discountType', discountType);
    formData.append('discountAmount', discountAmount);
    if (minimumOrderValue) {
      formData.append('minimumOrderValue', minimumOrderValue);
    }
    formData.append('totalUsageLimit', totalUsageLimit);
    if (maxDiscountAmount) {
      formData.append('maxDiscountAmount', maxDiscountAmount);
    }
    formData.append('category', category);
    formData.append('currency', currency);
    formData.append('country', country);
    formData.append('startDate', startDate);
    formData.append('endDate', endDate);
    if (status) {
      formData.append('status', status);
    }
    formData.append('usagePerUserLimit', usagePerUserLimit);

    if (typeof image === 'object') {
      formData.append('image', image);
    }

    if (category === 'Specific customer' && selCustomer && Array.isArray(selCustomer)) {
      const userIds = selCustomer.map((customer) => customer.id).join(',');
      formData.append('specificUser', userIds);
    }

    if (id) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      result = yield call(request.put, endpoints.updatePromotion + id, formData);
    } else {
      result = yield call(request.post, endpoints.addPromotion, formData);
    }
    yield put(addPromotionSuccess(result.data));
  } catch (err: any) {
    yield put(addPromotionFailed(err));
  }
}
//
function* getPromotionDetailSaga(action: ReturnType<typeof getPromotionDetails>): any {
  const { id } = action.payload;
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(request.get, endpoints.getPromotionDetails + id);
    yield put(getPromotionDetailsSuccess(result.data));
  } catch (err: any) {
    yield put(getPromotionDetailsFailed(err));
  }
}
//
// ----------------------------------------
function* updatePromotionStatusSagas(action: ReturnType<typeof updatePromotionStatus>): any {
  const { id } = action.payload;
  try {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(request.patch, endpoints.updatePromotionStatus + id);
    yield put(updatePromotionStatusSuccess(result.data.message));
  } catch (err: any) {
    yield put(updatePromotionStatusFailed(err));
  }
}

function* promotionSagas() {
  yield all([
    takeLatest(getAllPromotion, getAllPromotionSaga),
    takeLatest(addPromotion, addPromotionSagas),
    takeLatest(getPromotionDetails, getPromotionDetailSaga),
    takeLatest(updatePromotionStatus, updatePromotionStatusSagas),
  ]);
}

export { promotionSagas };
