import * as Yup from 'yup';

import { Strings } from '@app/constants';

// ---------------addProductSchema-------------
export const addProductSchema = Yup.object()
  .shape({
    name: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.name))
      .test('no-leading-whitespace', 'Name should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    desc: Yup.string()
      .required(Strings.validation.fieldRequired(Strings.field.description))
      .test('no-leading-whitespace', 'Description should not allowed white spaces', (value) =>
        value ? !/^\s/.test(value) : false
      ),
    categoryId: Yup.mixed()
      .notOneOf([''], Strings.validation.fieldRequired(Strings.field.category))
      .required(Strings.validation.selectRequired(Strings.field.category)),
    petType: Yup.mixed()
      .notOneOf([''], Strings.validation.selectRequired(Strings.field.petType))
      .required(Strings.validation.selectRequired(Strings.field.petType)),
    image: Yup.string().required(Strings.validation.uploadRequired(Strings.field.image)),
  })
  .required();
