import { IconButton } from '@mui/material';

import {
  Iconify,
  MenuPopover,
  RowActions as MenuRowActions,
  useMenuPopover,
} from '@app/components';

const RowActions = ({ handleDelete }: any) => {
  const menuPopover = useMenuPopover();

  const actions = [{ label: 'Delete', callback: handleDelete }];

  return (
    <>
      <IconButton
        onClick={menuPopover.show}
        sx={{
          '&:hover': {
            backgroundColor: 'transparent',
            opacity: 1,
          },
        }}
      >
        <Iconify
          icon="eva:more-vertical-fill"
          sx={{
            '&:hover': {
              transform: 'none',
              opacity: 1,
            },
          }}
        />
      </IconButton>

      <MenuPopover
        open={menuPopover.rowMenuAnchorElement}
        arrow="right-top"
        sx={{ width: 180 }}
        onClose={menuPopover.hide}
      >
        <MenuRowActions actions={actions} onClickAction={menuPopover.hide} />
      </MenuPopover>
    </>
  );
};

export { RowActions };
