import dayjs from 'dayjs';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Stack,
  Button,
  Typography,
  Divider,
  useTheme,
  MenuItem,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { useForm, useWatch } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';

import {
  FormProvider,
  Iconify,
  RHFDatePicker,
  RHFRadioGroup,
  RHFSelect,
  RHFTextField,
  RHFUploadAvatar,
  Scrollbar,
} from '@app/components';
import { Header } from '@app/sections/header';
import { Strings } from '@app/constants';
import { PATH_DASHBOARD } from '@app/routes/paths';
import { useDialog, useRequestState } from '@app/hooks';
import { addPromotion } from '../slice';
import { selectAddPromotion } from '../selector';
import { addPromotionSchema } from '../validators';
import { IVoucher } from '../types';
import { SelectCustomerDialog } from './select-customer-dialog';

interface Props {
  row?: IVoucher;
}

const AddPromotion = ({ row }: Props) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const dispatch = useDispatch();

  const { state } = useLocation();

  const methods = useForm<any>({
    resolver: yupResolver(addPromotionSchema) as any,
    defaultValues: {
      currency: state?.data?.currency ? state?.data?.currency : 'aed',
      country: state?.data?.country ? state?.data?.country : 'uae',
      category: state?.data?.category,
      code: state?.data?.code,
      startDate: state?.data?.startDate ? state?.data?.startDate : null,
      endDate: state?.data?.endDate ? state?.data?.endDate : null,
      discountAmount: state?.data?.discountAmount,
      discountType: state?.data?.discountType ? state?.data?.discountType : 'Percentage',
      maxDiscountAmount: state?.data?.maxDiscountAmount ? state?.data?.maxDiscountAmount : null,
      minimumOrderValue: state?.data?.minimumOrderValue,
      name: state?.data?.name,
      totalUsageLimit: state?.data?.totalUsageLimit ? state?.data?.totalUsageLimit : null,
      usagePerUserLimit: state?.data?.usagePerUserLimit ? state?.data?.usagePerUserLimit : null,
      voucherType: 'Discount',
      status: state?.data?.status,
      id: state?.data?.id,
      image: state?.data?.image ? state?.data?.image : null,
      description: state?.data?.description,
      categorySelected:
        state?.data?.specificUser?.length > 0
          ? state?.data?.specificUser
              ?.map((cut: any) => (cut.firstName ? cut.firstName : 'N/A'))
              .join(',')
          : '',
      selCustomer:
        state?.data?.specificUser?.length > 0
          ? state?.data?.specificUser?.map((cut: any) => cut)
          : [],
    },
  });

  const discountType = useWatch({ control: methods.control, name: 'discountType' });

  useEffect(() => {
    methods.reset({
      currency: state?.data?.currency ? state?.data?.currency : 'aed',
      country: state?.data?.country ? state?.data?.country : 'uae',
      voucherType: 'Discount',
      category: state?.data?.category,
      code: state?.data?.code,
      discountAmount: state?.data?.discountAmount,
      discountType: state?.data?.discountType ? state?.data?.discountType : 'Percentage',
      maxDiscountAmount: state?.data?.maxDiscountAmount ? state?.data?.maxDiscountAmount : null,
      minimumOrderValue: state?.data?.minimumOrderValue,
      name: state?.data?.name,
      totalUsageLimit: state?.data?.totalUsageLimit ? state?.data?.totalUsageLimit : null,
      usagePerUserLimit: state?.data?.usagePerUserLimit ? state?.data?.usagePerUserLimit : null,
      status: state?.data?.status,
      id: state?.data?.id,
      image: state?.data?.image,
      startDate: state?.data?.startDate ? state?.data?.startDate : null,
      endDate: state?.data?.endDate ? state?.data?.endDate : null,
      description: state?.data?.description,
      categorySelected:
        state?.data?.specificUser?.length > 0
          ? state?.data?.specificUser
              ?.map((cut: any) => (cut.firstName ? cut.firstName : 'N/A'))
              .join(',')
          : '',
      selCustomer:
        state?.data?.specificUser?.length > 0
          ? state?.data?.specificUser?.map((cut: any) => cut)
          : [],
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  const [initialValues, setInitialValues] = useState<any>(null);
  const selectCustomerDialog = useDialog();

  useEffect(() => {
    if (discountType.trim().toLowerCase() === 'amount') {
      methods.setValue('maxDiscountAmount', null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [discountType]);

  useEffect(() => {
    if (state?.data) {
      setInitialValues(methods.getValues());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state?.data]);

  const onSubmit = (form: any) => {
    const hasChanged = JSON.stringify(form) !== JSON.stringify(initialValues);

    if (hasChanged) {
      dispatch(addPromotion(form));
    } else {
      navigate(PATH_DASHBOARD.promotion);
    }
  };

  const discountTypeRadioGroup = [
    { label: 'Percentage', value: 'Percentage' },
    { label: 'Amount', value: 'Amount' },
  ];

  const [isImageUploaded, setIsImageUploaded] = useState(false);

  const handleDrop = useCallback(
    (acceptedFiles: File[], name: string) => {
      const file = acceptedFiles[0];

      const newFile = Object.assign(file, {
        preview: URL.createObjectURL(file),
      });

      if (file) {
        methods.setValue(name, newFile, { shouldValidate: true });
        setIsImageUploaded(true);
      }
    },
    [methods]
  );
  const navigateToPromotionList = useCallback(() => {
    navigate(PATH_DASHBOARD.promotion);
  }, [navigate]);

  const { loading } = useRequestState({
    stateSelector: selectAddPromotion,
    successMessageShown: true,
    onSuccess: navigateToPromotionList,
    errorShown: true,
  });

  const handleAutoPopulate = () => {
    const offerCode = generateOfferCode();
    methods.setValue('code', offerCode, { shouldValidate: true });
  };

  const handleSubmitSelectedCustomer = (selCustomer: any) => {
    methods.setValue('selCustomer', selCustomer);

    const namesJoined = selCustomer
      .map((item: any) => (item.firstName ? item.firstName : 'N/A'))
      .join(', ');
    methods.setValue('categorySelected', namesJoined);
    selectCustomerDialog.hide();
  };

  const generateOfferCode = () => {
    const prefixes = ['WELCOME', 'SAVE', 'OFFER', 'DEAL', 'DISCOUNT', 'COUPON', 'HOT', 'PROMO'];
    const randomPrefix = prefixes[Math.floor(Math.random() * prefixes.length)];

    const randomNumber = Math.floor(Math.random() * 100)
      .toString()
      .padStart(2, '0');

    return `${randomPrefix}${randomNumber}`;
  };

  const categorySelected = methods.watch('category');
  const customerSelected = methods.watch('selCustomer');

  return (
    <>
      <Stack>
        <Header
          backButton
          title={state ? Strings.pageTitle.promotions : Strings.pageTitle.promotions}
          onClickBack={() => {
            navigateToPromotionList();
          }}
        />
      </Stack>
      <FormProvider methods={methods}>
        <Stack padding={4} gap={2}>
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              gap: 4,
              alignItems: 'center',
              flex: 1,
            }}
          >
            <Stack sx={{ flex: 0.1 }}>
              <RHFUploadAvatar
                name={'image'}
                onDrop={(acceptedFiles) => {
                  handleDrop(acceptedFiles, 'image');
                }}
                sx={{
                  borderRadius: '10px',
                  width: 110,
                  height: 110,
                  border: 'none',
                }}
                placeholderSx={{
                  borderRadius: '10px',
                  width: '100%',
                  height: '100%',
                  border:
                    isImageUploaded || row?.images ? 'none' : '5px dashed rgba(0, 0, 0, 0.08)',
                }}
              />
            </Stack>
            <Stack
              sx={{
                flexDirection: { sm: 'column', md: 'row' },
                gap: 3,
                flex: 1,
              }}
            >
              <Stack
                sx={{
                  gap: 1,
                  flex: 0.4,
                }}
              >
                <RHFTextField name="name" label={'Name'} required />
                <RHFDatePicker
                  name="startDate"
                  required
                  label={Strings.promotion.startDate}
                  onChange={(newValue: any) => {
                    methods.setValue('startDate', dayjs(newValue).toISOString());
                  }}
                  defaultValue={state?.data?.startDate ? dayjs(state.data.startDate) : null}
                  minDate={dayjs()}
                />
              </Stack>
              <Stack
                sx={{
                  gap: 1,
                  flex: 0.4,
                }}
              >
                <Stack sx={{ flexDirection: 'row', alignItems: 'center', gap: 1 }}>
                  <RHFTextField
                    name="code"
                    required
                    label={Strings.promotion.code}
                    InputLabelProps={{
                      shrink: methods.watch('code')?.length > 0,
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end" sx={{ marginLeft: 0 }}>
                          <IconButton sx={{ padding: 0 }} onClick={handleAutoPopulate}>
                            <Iconify
                              icon="solar:magic-stick-3-broken"
                              width={24}
                              fontWeight={200}
                            />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Stack>
                <RHFDatePicker
                  name="endDate"
                  required
                  label={Strings.promotion.endDate}
                  onChange={(newValue: any) => {
                    methods.setValue('endDate', dayjs(newValue).toISOString());
                  }}
                  defaultValue={state?.data?.endDate ? dayjs(state.data.endDate) : null}
                  minDate={methods.watch('startDate') ? dayjs(methods.watch('startDate')) : dayjs()}
                />
              </Stack>
              <Stack sx={{ flex: 0.4 }}>
                <RHFTextField
                  name="description"
                  required
                  label={Strings.promotion.description}
                  multiline
                  maxRows={3.5}
                  InputProps={{
                    endAdornment: <Scrollbar sx={{ marginLeft: 0 }} />,
                  }}
                  sx={{
                    height: 'auto',
                    '& textarea': {
                      maxHeight: '150px',
                      overflow: 'auto',
                      scrollbarWidth: 'thin',
                      '&::-webkit-scrollbar': {
                        width: '1px',
                      },
                    },
                  }}
                />
              </Stack>
            </Stack>
          </Stack>

          <Stack sx={{ gap: 1 }}>
            <Stack sx={{ flexDirection: 'row', gap: { sm: 10, md: 20 } }}>
              <Typography sx={{ fontWeight: 700 }}>{Strings.promotion.discountType}</Typography>
            </Stack>
            <Divider />
            <Stack sx={{ flexDirection: 'row', gap: { sm: 10, md: 20 } }}>
              <RHFRadioGroup
                sx={{ gap: 0.3, color: theme.palette.grey[700], flexDirection: 'row' }}
                radioSx={{ color: theme.palette.primary.main }}
                name={'discountType'}
                options={discountTypeRadioGroup}
              />
            </Stack>
          </Stack>
          <Divider />
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              gap: 6,
              flex: 1,
            }}
          >
            <RHFTextField
              sx={{ flex: 0.5 }}
              required
              name="discountAmount"
              label={
                discountType.trim().toLowerCase() === 'percentage'
                  ? Strings.promotion.discountAmountPercentage
                  : Strings.promotion.discountAmount
              }
            />
            {discountType && discountType.trim().toLowerCase() === 'percentage' && (
              <RHFTextField
                sx={{ flex: 0.5 }}
                required
                name="maxDiscountAmount"
                label={Strings.promotion.maximumAmount}
                defaultValue={null}
              />
            )}
          </Stack>
          <Typography sx={{ fontWeight: 700 }}>Products</Typography>
          <Divider />
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              gap: 6,
              flex: 1,
            }}
          >
            <RHFSelect
              name="category"
              required
              label={Strings.promotion.category}
              variant="outlined"
              defaultValue={'uae'}
              select
              sx={{ flex: 1 }}
            >
              <MenuItem value="New customer">{Strings.promotion.newCustomer}</MenuItem>
              <MenuItem value="All customer">{Strings.promotion.allCustomer}</MenuItem>
              <MenuItem value="Existing customer">{Strings.promotion.existingCustomer}</MenuItem>
              <MenuItem value="Specific customer">{Strings.promotion.specificCustomer}</MenuItem>
            </RHFSelect>
            {categorySelected?.toLowerCase().replace(/\s+/g, '').trim() === 'specificcustomer' && (
              <Stack
                sx={{ cursor: 'pointer' }}
                onClick={() => {
                  selectCustomerDialog.show();
                }}
              >
                <RHFTextField
                  sx={{
                    pointerEvents: 'none',
                    flex: 1,
                    '& .MuiInputBase-input': {
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    },
                  }}
                  name="categorySelected"
                  label="Specific Customer"
                  disabled
                  InputLabelProps={{
                    shrink: methods.watch('categorySelected')?.length > 0,
                  }}
                />
              </Stack>
            )}
            <RHFSelect
              name="country"
              label={Strings.field.country}
              variant="outlined"
              defaultValue={'uae'}
              sx={{ flex: 1 }}
              select
            >
              <MenuItem value="uae">{Strings.promotion.uae}</MenuItem>
            </RHFSelect>
            <RHFSelect
              name="currency"
              label={Strings.promotion.currency}
              variant="outlined"
              defaultValue={'AED'}
              select
              sx={{ flex: 1 }}
            >
              <MenuItem value="aed">{'AED'}</MenuItem>
            </RHFSelect>
          </Stack>
          <Stack
            sx={{
              flexDirection: { sm: 'column', md: 'row' },
              gap: 6,
            }}
          >
            <RHFTextField required name="totalUsageLimit" label={Strings.promotion.totalUsage} />
            <RHFTextField required name="usagePerUserLimit" label={Strings.promotion.usagePerson} />
            <RHFTextField
              name="minimumOrderValue"
              label={Strings.promotion.mov}
              required={methods.watch('discountType').toLowerCase().trim() === 'amount'}
            />
          </Stack>

          <Stack marginBottom={3} sx={{ flexDirection: 'row', gap: 1 }}>
            <Button
              variant="outlined"
              onClick={() => {
                navigate(-1);
              }}
            >
              {Strings.button.cancel}
            </Button>
            <LoadingButton
              loading={loading}
              variant="contained"
              type="submit"
              disabled={
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(methods.watch() || {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                ) ===
                JSON.stringify(
                  Object.fromEntries(
                    Object.entries(initialValues || {}).map(([key, value]) => [
                      key,
                      value?.toString() ?? '',
                    ])
                  )
                )
              }
              onClick={methods.handleSubmit(onSubmit)}
            >
              {Strings.button.save}
            </LoadingButton>
          </Stack>
        </Stack>
        {selectCustomerDialog.visible && (
          <SelectCustomerDialog
            open
            onClose={selectCustomerDialog.hide}
            handleSubmit={handleSubmitSelectedCustomer}
            selectedCustomers={customerSelected}
          />
        )}
      </FormProvider>
    </>
  );
};

export { AddPromotion };
