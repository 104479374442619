import { RootState } from '@app/redux/root-reducer';

export const selectAddCustomerPopupState = (store: RootState) =>
  store.customerPopupReducer.addCustomerPopup;

export const selectCustomerPopups = (store: RootState) => store.customerPopupReducer.customerPopup;

export const selectDeleteCustomerPopup = (store: RootState) =>
  store.customerPopupReducer.deleteCustomerPopup;

export const selectUpdateCustomerPopupStatus = (store: RootState) =>
  store.customerPopupReducer.updateCustomerPopupStatus;
