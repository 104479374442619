import { all, call, takeLatest, put, select } from 'redux-saga/effects';
import dayjs from 'dayjs';

import { request } from '@app/services';
import {
  addSlot,
  getAllSlot,
  getAllSlotSuccess,
  getAllSlotFailed,
  addSlotSuccess,
  addSlotFailed,
  updateSlotStatus,
  updateSlotStatusSuccess,
  updateSlotStatusFailed,
} from './slice';
import * as endpoints from './endpoints';
import { setPageCount } from '../common';
import { RootState } from '@app/redux/root-reducer';

function* getAllSlotSaga(action: ReturnType<typeof getAllSlot>): any {
  const { page, q, limit = 100 } = action.payload;
  try {
    const result = yield call(request.get, endpoints.getAllSlot, {
      params: {
        page: q ? 1 : page,
        limit,
        q,
      },
    });
    yield put(getAllSlotSuccess(result.data.data));
    yield put(setPageCount({ id: action.type, count: result.data.total }));
  } catch (err: any) {
    yield put(getAllSlotFailed(err));
  }
}

function* updateSlotStatusSagas(action: ReturnType<typeof updateSlotStatus>): any {
  const { id } = action.payload;
  try {
    const { data } = yield select((store: RootState) => store.addSlotSlice.slots);
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    const result = yield call(request.patch, endpoints.updateSlotStatus + id);
    yield put(updateSlotStatusSuccess(result.data.message));
    const updatedData = data.map((slot: any) => {
      if (slot.id === id) {
        return {
          ...slot,
          active: !slot.active,
        };
      }
      return slot;
    });
    yield put(getAllSlotSuccess(updatedData));
  } catch (err: any) {
    yield put(updateSlotStatusFailed(err));
  }
}

function* addSlotSaga(action: ReturnType<typeof addSlot>): any {
  try {
    const {
      id,
      name,
      slotType,
      deliveryStartTime,
      deliveryEndTime,
      duration,
      orderCutOff,
      deliveryCharge,
      capacity,
      active = true,
      specificDate,
      availableDays = [],
      city,
    } = action.payload;

    const formData = new FormData();

    formData.append('active', 'true');
    let result;
    if (id) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      result = yield call(request.put, endpoints.updateSlot + id, {
        name,
        slotType,
        deliveryStartTime: dayjs(deliveryStartTime).format('HH:mm:ss'),
        deliveryEndTime: dayjs(deliveryEndTime).format('HH:mm:ss'),
        duration,
        orderCutOff: orderCutOff ? dayjs(orderCutOff).format('HH:mm:ss') : null,
        deliveryCharge,
        capacity,
        active,
        specificDate,
        availableDays,
        city,
      });
    }
    if (!id) {
      result = yield call(request.post, endpoints.addSlot, {
        name,
        slotType,
        deliveryStartTime: dayjs(deliveryStartTime).format('HH:mm:ss'),
        deliveryEndTime: dayjs(deliveryEndTime).format('HH:mm:ss'),
        duration,
        orderCutOff: orderCutOff ? dayjs(orderCutOff).format('HH:mm:ss') : null,
        deliveryCharge,
        capacity,
        active,
        specificDate,
        availableDays,
        city,
      });
    }
    if (result?.data.status === true) {
      yield put(addSlotSuccess(result.data));
    } else {
      const errorMessage = result?.data.message;
      yield put(addSlotFailed({ message: errorMessage }));
    }
  } catch (err: any) {
    yield put(addSlotFailed(err));
  }
}

function* slotSagas() {
  yield all([
    takeLatest(addSlot, addSlotSaga),
    takeLatest(getAllSlot, getAllSlotSaga),
    takeLatest([updateSlotStatus], updateSlotStatusSagas),
  ]);
}

export { slotSagas };
